/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';

import { Container, Content, ContentContainer } from './IntegrationPanel.style';
import { useTranslation } from 'react-i18next';
import ListItem from '../../components/ListItem/ListItem';
import { ReactComponent as SortDescIcon } from '../../assets/icon-sort-desc.svg';
import { ReactComponent as SortAscIcon } from '../../assets/icon-sort-asc.svg';
import ListButton from '../../components/ListButton/ListButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import api from '../../services/api';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import { userHasAnyPermissionOf } from '../../services/permission-service';
import IntegrationService from '../../services/integration-service';
import GoTopButton from '../../shared/GoTopButton/GoTopButton';

interface IntegrationPanel {
  items: Items[];
}

interface Items {
  center: string;
  cod_quotation: string;
  code: string;
  errors: string[];
  id: number;
  integration_status: string;
  item_req: number;
  material: string;
  num_quotation: number;
  retries: number;
  socialName: string[];
  urgency: string;
  suppliers: Array<Supplier>;
  [index: string]: string | number | string[] | Supplier[];
}

interface Supplier {
  code: string;
  social_name: string;
}

interface IntegrationContentLine {
  suppliers: Array<string>;
  social_name: Array<string>;
  error_detail: Array<string>;
}

interface SortFieldProps {
  name: string;
  value: string;
}

interface IntegrationProps {
  currentIntegrationList: number;
  integrationPanel: Items[];
  fetchIntegrations(): void;
  showLoader(value: boolean): void;
  updateIntegrationPanel: Function;
  errorPage: Function;
}

interface Field {
  label: string;
  sort?: string;
  value?: string;
  size?: string;
  type?: string;
}

const IntegrationPanel: React.FC<IntegrationProps> = ({
  currentIntegrationList,
  integrationPanel,
  fetchIntegrations,
  showLoader,
  updateIntegrationPanel,
  errorPage,
}) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<SortFieldProps>({
    name: '',
    value: '',
  });

  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [modalIsOpen, setIsOpen] = useState(false);

  let message = 'sent_to_reprocess';
  async function reprocessQuotation(id: number) {
    try {
      const response = await api.post(`quotations/${id}/re_send_to_AI_data/`);
      if (response.status === 200) openModal('sent_to_reprocess');
      else openModal('request_error');
    } catch (error) {
      openModal('request_error');
    }
  }
  function openModal(text: string) {
    message = text;
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const fields: Field[] = [
    { label: 'process_id', value: 'id', type: 'number', sort: 'id' },
    {
      label: 'requisition',
      size: 'w-28',
      value: 'cod_quotation',
      sort: 'cod_quotation',
    },
    {
      label: 'requisition_item',
      size: 'w-36',
      value: 'item_req',
      type: 'number',
      sort: 'quotation_items_suppliers__quotation_items',
    },
    {
      label: 'material',
      type: 'number',
      sort:
        'quotation_items_suppliers__quotation_items__item__material__cod_material',
    },
    {
      label: 'center',
      type: 'number',
      sort:
        'quotation_items_suppliers__quotation_items__item__item_purchase_requisition__branch_region',
    },
    {
      label: 'urgency',
      size: 'w-36',
      sort:
        'quotation_items_suppliers__quotation_items__item__item_purchase_requisition__urgency_status',
    },
    {
      label: 'status_integration',
      size: 'w-44',
      value: 'integration_status',
      sort: 'integration_status',
    },
    {
      label: 'try',
      type: 'number',
      value: 'retries',
      sort: 'integration_retries',
    },
    { label: 'process' },
  ];

  const handleSort = async (field: Field) => {
    showLoader(true);
    let url = '';
    let sort = sortField.value === 'asc' ? 'desc' : 'asc';
    if (sortField.name !== field.label) {
      sort = 'asc';
    }
    setSortField({ name: field.label, value: sort });
    if (field.sort) {
      url = `order_by=${field.sort}-${sort}`;
    }
    await api
      .get(`/integrations/panel/?${url}`)
      .then(response => {
        updateIntegrationPanel(response.data.results);
      })
      .catch(error => {
        errorPage(error);
      })
      .finally(() => {
        showLoader(false);
      });
  };

  return (
    <Container className="flex-1 flex-col">
      <div
        ref={contentRef}
        id="scrollableDiv"
        className="flex overflow-y-scroll w-full px-16 justify-center"
      >
        <Content className="w-full" style={{ height: '87vh' }}>
          <div className="flex justify-end w-full pt-2">
            <ListButton
              keyButtonText={'export_list'}
              permissionsNeeded={['add_quotation', 'change_quotation']}
              toogleAction={IntegrationService.exportCsv}
            />
            {integrationPanel && integrationPanel.length > 0 && (
              <GoTopButton content={contentRef.current} />
            )}
          </div>
          <div className="flex w-full justify-between items-center px-4 pt-1">
            {fields.map((field, index) => (
              <div
                key={index}
                className={`flex items-center ${field.size || 'flex-1'}`}
              >
                {sortField.name === field.label ? (
                  sortField.value === 'desc' ? (
                    <SortDescIcon
                      className="cursor-pointer w-3 h-2.5"
                      onClick={() => handleSort(field)}
                    />
                  ) : (
                    <SortAscIcon
                      className="cursor-pointer w-3 h-2.5"
                      onClick={() => handleSort(field)}
                    />
                  )
                ) : (
                  <SortDescIcon
                    className="cursor-pointer w-3 h-2.5"
                    onClick={() => handleSort(field)}
                  />
                )}
                <span
                  title={`${t(field.label)}`}
                  className="font-default select-none font-bold text-sm text-bluejeans-800 ml-2"
                >
                  {t(field.label)}
                </span>
              </div>
            ))}
            <div className="flex h-3 w-4" />
          </div>
          <InfiniteScroll
            dataLength={currentIntegrationList}
            hasMore={true}
            next={fetchIntegrations}
            loader={<></>}
            scrollableTarget="scrollableDiv"
          >
            {integrationPanel !== undefined &&
              integrationPanel.map((item, key) => {
                const FeaturedComponent: React.FC = () => {
                  const labels = [
                    { label: item.id },
                    { label: item.cod_quotation, size: 'w-28' },
                    { label: item.item_req, size: 'w-36' },
                    { label: item.material },
                    { label: item.center },
                    { label: item.urgency, size: 'w-36' },
                    { label: item.integration_status, size: 'w-44' },
                    { label: item.retries },
                    { label: 'reprocess', process: true, id: item.id },
                  ];

                  return (
                    <>
                      {labels.map((item, key) => (
                        <span
                          className={`font-default font-normal text-sm text-yaleblue-900 ${
                            item.size || 'flex-1'
                          }`}
                          key={key}
                        >
                          {item.process &&
                          userHasAnyPermissionOf(['change_quotation']) ? (
                            <button
                              onClick={() => reprocessQuotation(item.id)}
                              className="underline text-bluejeans-800 font-semibold cursor-pointer"
                            >
                              {item.label ? t(item.label) : `-`}
                            </button>
                          ) : (
                            <span>
                              {item.label && !item.process ? item.label : `-`}
                            </span>
                          )}
                        </span>
                      ))}
                    </>
                  );
                };

                const ContentComponent: React.FC = () => {
                  const HistoricDataLine: IntegrationContentLine = {
                    suppliers: item.suppliers.map(sup => sup.code),
                    social_name: item.suppliers.map(sup => sup.social_name),
                    error_detail: item.errors.map(error => error),
                  };
                  return (
                    <>
                      <div key={key} className="flex flex-row">
                        {Object.entries(HistoricDataLine).map(
                          (lineItem, key) => (
                            <ContentContainer
                              key={key}
                              className="flex flex-col justify-center items-center pb-3 px-3"
                            >
                              <span className="font-default font-normal text-xs text-yaleblue-1000">
                                {t(lineItem[0])}
                              </span>
                              {lineItem[1].map(
                                (content: string | number, key: number) => (
                                  <div
                                    key={key}
                                    className="font-default font-normal text-sm text-yaleblue-1000 mt-2.5"
                                  >
                                    {content || `-`}
                                  </div>
                                ),
                              )}
                            </ContentContainer>
                          ),
                        )}
                      </div>
                    </>
                  );
                };
                return (
                  <ListItem
                    key={key}
                    FeaturedComponent={FeaturedComponent}
                    ContentComponent={ContentComponent}
                    className="mt-2"
                    id={key}
                    hasCheckbox={false}
                  />
                );
              })}
          </InfiniteScroll>
          {integrationPanel?.length === 0 && (
            <div className="mt-6 flex justify-center items-center">
              <span className="font-default text-sm font-light italic text-300">
                {t('empty_list')}
              </span>
            </div>
          )}
        </Content>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <div className="my-4">
            <span className="font-default font-normal text-sm text-yaleblue-1000">
              {`${t(message)} `}
            </span>
          </div>

          <Button onClick={() => closeModal()}>
            {t('confirmation_modal')}
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default IntegrationPanel;
