import api from './api';
import i18n from 'i18next';

export interface FilterParam {
  key: string;
  value: string | string[] | number[] | number;
  order?: null | number;
  label?: null | string;
}

export interface ListParams {
  page: number;
  limit: number;
  sort: string;
  order: string;
  offset?: number;
  order_by?: string;
  group_count: string;
  filter: FilterParam[];
}

export interface ListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: unknown[];
}
export default abstract class BaseService {
  abstract exportUrl: string;
  abstract listUrl: string;

  buildUrlParams = (params: ListParams, paginate = true): string => {
    if (!params.page) params.page = 1;

    const filters = params.filter;

    if (paginate) {
      const offset = params.limit * (params.page - 1);
      const order_by = params.sort + '-' + params.order;
      const pagination: FilterParam[] = [
        { key: 'order_by', value: order_by },
        { key: 'offset', value: offset },
        { key: 'limit', value: params.limit },
      ];
      filters.push(...pagination);
    }

    return this.buildUrlFromFilters(filters);
  };

  buildUrlFromFilters = (filters: FilterParam[]): string => {
    const urlParams = filters.map(({ key, value }) => `${key}=${value}`);
    return urlParams.join('&');
  };

  getList = async (params: ListParams): Promise<ListResponse> => {
    const query = this.buildUrlParams(params);
    const response = await api.get(`${this.listUrl}?${query}`);
    return response.data;
  };

  exportCsv = async (params: ListParams | undefined): Promise<string[][]> => {
    let query = '';
    if (params) {
      query = this.buildUrlParams(params);
    }
    const response = await api.get(`${this.exportUrl}?${query}`);
    const parsedCsv = response.data.split('\r\n');
    const csvContent: string[][] = [];
    for (let i = 0; i < parsedCsv.length - 1; i++) {
      parsedCsv[i] = parsedCsv[i].replace(/;;/g, ';-;');
      parsedCsv[i] = parsedCsv[i].replace(/;;/g, ';');
      csvContent.push(parsedCsv[i].split(';'));
    }
    if (csvContent.length !== 0) {
      const translatedHeaders = csvContent[0];

      translatedHeaders.forEach((item, key) => {
        csvContent[0][key] = i18n.t(item.replace(/\s/g, ''));
      });
    }
    return csvContent;
  };
}
