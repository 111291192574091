import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

import {
  Content,
  ContentContainer,
  ContentLastPurchase,
  Button,
} from './ListPurchaseRequisition.style';
import { useTranslation } from 'react-i18next';
import ListItem from '../../../components/ListItem/ListItem';
import Filters from '../../../components/Filters/Filters';
import FilterButton from '../../../components/FilterButton/FiltterButton';

import { ReactComponent as UserIcon } from '../../../assets/user-icon.svg';
import { ReactComponent as UsersIcon } from '../../../assets/users-icon.svg';
import { ReactComponent as UserIconSelec } from '../../../assets/user-icon-selec.svg';
import { ReactComponent as UsersIconSelec } from '../../../assets/users-icon-selec.svg';
import SortIcon from '../../../shared/SortIcon/SortIcon';
import { formatOptions } from '../../../helpers/OptionsHelpers/FormatOptions';
import ListButton from '../../../components/ListButton/ListButton';
import { Link, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { format } from 'date-fns';
import Loading from '../../../components/Loading/Loading';
import DeadlineDonut from '../../../components/DeadlineDonut/DeadlineDonut';
import removeZerosLeft from '../../../utils/removeZerosLeft';
import { formatStringToCurrency } from '../../../utils/formatValue';
import PurchaseRequisitionService, {
  RcFilterCounterGroup,
  RcFilterCounterStControl,
  RcFilterCounter,
} from '../../../services/purchase-requisition-service';
import GoTopButton from '../../../shared/GoTopButton/GoTopButton';
import { userHasAnyPermissionOf } from '../../../services/permission-service';
import { FilterParam, ListParams } from '../../../services/base-service';
import api from '../../../services/api';
import HiddenFileInput from '../../../components/HiddenFileInput/HiddenFileInput';
import { CustomComponent, CustomFilterPosition } from '../../../styled';

interface BuyerGroup {
  id: number;
  name: string;
  description: string;
}

interface material {
  id: number;
  cod_material: string;
  description: string;
}

interface LastPurchaseSupplier {
  id: number;
  code: string;
  social_name: string;
  nickname: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  PMP: number;
  saving_percent: number;
  deadline: string;
}

interface MerchandiseGroup {
  id: number;
  description: string;
  erp_id: string;
}

interface ItemPurchaseRequisition {
  id: number;
  num_req: string;
  dt_lib_rc: string;
  created_on: string;
}

interface Items {
  id: number;
  quantity: number;
  item_req: string | number;
  material: material;
  dt_lib_rc: string;
  item_purchase_requisition: ItemPurchaseRequisition;
  merchandise_group: MerchandiseGroup;
  short_description: string;
  long_description: string;
  dt_delivery: string;
  sla_deadline: string;
  created_on: string;
  is_active: boolean;
  urgency_status: string;
  mercadory_category: string;
  status: string;
  st_control: number;
  branch_region: string;
  warehouse: string;
  dt_shipping: string;
  last_purchase_price: string;
  last_purchase_date: string;
  last_purchase_supplier: LastPurchaseSupplier | null;
  last_purchase_minimum_lot: string;
  last_purchase_order_number: string;
  buyer_group: BuyerGroup | null;
  vendor_list: string;

  [key: string]:
    | number
    | string
    | material
    | MerchandiseGroup
    | ItemPurchaseRequisition
    | BuyerGroup
    | boolean
    | LastPurchaseSupplier
    | null
    | Items[];
}

interface Field {
  label: string;
  sort?: string;
  value?: string;
  size?: string;
  type?: string;
}

interface Options {
  label: string;
  value: string | number;
}

interface PurchaseRequisitionProps {
  errorPage: Function;
}

const PurchaseRequisition: React.FC<PurchaseRequisitionProps> = ({
  errorPage,
}) => {
  const { t } = useTranslation();
  const [numReq, setNumReq] = useState<Options[]>([]);
  const [buyerGroups, setBuyerGroups] = useState<Options[]>([]);
  const [merchadiseGroups, setmerchadiseGroups] = useState<Options[]>([]);
  const [materials, setMaterials] = useState<Options[]>([]);
  const [regions, setRegions] = useState<Options[]>([]);
  const [urgencies, setUrgencies] = useState<Options[]>([]);
  const [warehouses, setWarehouses] = useState<Options[]>([]);
  const [
    filterCountGroup,
    setFilterCountGroup,
  ] = useState<RcFilterCounterGroup>(
    PurchaseRequisitionService.filterCountGroupDefault,
  );

  const [filterCount, setFilterCount] = useState<RcFilterCounter>(
    PurchaseRequisitionService.filterCountDefault,
  );
  const [items, setItems] = useState<Items[]>([]);

  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<ListParams>(
    PurchaseRequisitionService.paramsDefault,
  );
  const [hasNext, setHasNext] = useState(true);
  const [quickFilters, setQuickFilters] = useState<FilterParam[]>([]);
  interface FilterState {
    [key: string]: FilterParam[];
  }

  const initialFilters: FilterState = {
    '0': PurchaseRequisitionService.filterButtons[0].all,
    '1': PurchaseRequisitionService.filterButtons[1].pending,
  };
  const [filterState, setFilterState] = useState<FilterState>(initialFilters);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedItemsSameRegion, setSelectedItemsSameRegion] = useState(true);
  const [filters, setFilters] = useState<FilterParam[]>([]);
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const dateFormat = 'dd/MM/yy';

  const filterFields = {
    fieldsList: [
      {
        field: 'num_req',
        type: 'list',
        placeholder: 'Ex. 18831544',
        options: numReq,
        url: 'purchase_requisitions/list_to_filter/?num_req=',
      },
      {
        field: 'buyer_group_id',
        type: 'list',
        placeholder: 'select_buyer_group',
        options: buyerGroups,
        url: 'buyer_groups/list_to_filter/?name=',
      },
      {
        field: 'merchandise_group',
        type: 'list',
        placeholder: 'select_merchandise_group',
        options: merchadiseGroups,
        url: 'merchandise_groups/list_to_filter/?description=',
      },
      {
        field: 'material',
        type: 'list',
        placeholder: 'Ex. 175239',
        options: materials,
        url: 'materials/list_to_filter/?cod_material=',
      },
      {
        field: 'urgency',
        type: 'select',
        placeholder: 'select_urgency',
        options: urgencies,
        url: 'sla/list_to_filter/?code=',
      },
      {
        field: 'region',
        type: 'list',
        placeholder: 'select_region',
        options: regions,
        url: 'centers/list_to_filter/?name=',
      },
      {
        field: 'warehouse',
        type: 'list',
        placeholder: 'select_warehouse',
        options: warehouses,
        url: 'warehouses/list_to_filter/?code=',
      },
      {
        field: 'vendor_list',
        type: 'select',
        placeholder: 'has_vendor_list',
        options: [
          {
            label: t('Yes'),
            value: 'True',
          },
          {
            label: t('No'),
            value: 'False',
          },
          {
            label: t('not_selected'),
            value: 'None',
          },
        ],
      },
      // {
      //   field: 'vendor_list',
      //   type: 'checkbox',
      //   placeholder: 'has_vendor_list',
      // },
      // {
      //   field: 'st_control',
      //   type: 'select',
      //   placeholder: 'select_st_control',
      //   options: Object.entries(PURCHASE_REQUISITION_STATUS).map(
      //     ([key, value]) => ({
      //       label: t(key),
      //       value: value,
      //     }),
      //   ),
      // },
    ],
    hasSla: true,
  };

  const filterButtons = {
    0: [
      {
        button: 'mine',
        keyText: `${t('my_plural')} (${filterCountGroup.mine.total})`,
        icon: UserIcon,
        iconSelec: UserIconSelec,
      },
      {
        button: 'all',
        keyText: `${t('all')} (${filterCountGroup.all.total})`,
        icon: UsersIcon,
        iconSelec: UsersIconSelec,
      },
    ],
    1: [
      {
        button: 'all',
        keyText: `${t('all')} (${filterCount.st_control.total})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'pending',
        keyText: `${t('pending')} (${filterCount.st_control.pending})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'waiting_response',
        keyText: `${t('waiting_response')} 
        (${filterCount.st_control.waiting_response})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'failure',
        keyText: `${t('failure')} (${filterCount.st_control.failure})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'manual_decision',
        keyText: `${t('manual_decision')} 
        (${filterCount.st_control.manual_decision})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'finished',
        keyText: `${t('finished')} (${filterCount.st_control.finished})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'order_failed',
        keyText: `${t('order_failed')} (${
          filterCount.st_control.order_failed
        })`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'resolved_externally',
        keyText: `${t('resolved_externally')} 
        (${filterCount.st_control.resolved_externally})`,
        icon: null,
        iconSelec: null,
      },
    ],
  };

  const fields: Field[] = [
    {
      label: 'requisition',
      value: 'item_purchase_requisition.num_req',
      size: 'md:w-3/36 lg:w-3/36',
      sort: 'item_purchase_requisition__num_req',
    },
    {
      label: 'item',
      value: 'id',
      size: 'md:w-2/36 lg:w-2/36',
      type: 'number',
      sort: 'item_req',
    },
    {
      label: 'material',
      value: 'material.cod_material',
      size: 'md:w-2/36 lg:w-2/36',
      type: 'number',
      sort: 'material__cod_material',
    },
    {
      label: 'center',
      value: 'item_purchase_requisition.branch_region',
      size: 'md:w-2/36 lg:w-2/36',
      type: 'number',
      sort: 'branch_region',
    },
    {
      label: 'warehouse',
      value: 'item_purchase_requisition.warehouse',
      size: 'md:w-2/36 lg:w-2/36',
      type: 'number',
      sort: 'warehouse',
    },
    {
      label: 'description',
      size: 'md:w-8/36 lg:w-8/36',
      value: 'description',
      sort: 'short_description',
    },
    {
      label: 'merchandise_gp',
      value: 'item_purchase_requisition.mercadory_category',
      size: 'md:w-3/36 lg:w-3/36',
      sort: 'mercadory_category',
    },
    {
      label: 'urgency',
      value: 'item_purchase_requisition.urgency_status',
      size: 'md:w-2/36 lg:w-2/36',
      sort: 'urgency_status',
    },
    {
      label: 'sla_deadline',
      value: 'sla_deadline',
      size: 'md:w-3/36 lg:w-3/36',
      type: 'date',
      sort: 'sla_deadline',
    },
    {
      label: 'vendor_list',
      value: 'vendor_list',
      size: 'md:w-2/36 lg:w-2/36',
      sort: 'vendor_list',
    },
    {
      label: 'remittance_dt',
      value: 'item_purchase_requisition.last_purchase_date',
      size: 'md:w-2/36 lg:w-2/36',
      type: 'date',
      sort: 'dt_delivery',
    },
    {
      label: 'status',
      value: 'status',
      size: 'md:w-2/36 lg:w-2/36',
    },
  ];
  const [checkCache, setcheckCache] = useState(false);
  useLayoutEffect(() => {
    const general_filter_value =
      sessionStorage.getItem('purchase_requisition_list_general_filter') ||
      localStorage.getItem('purchase_requisition_list_general_filter');
    let general_filter;
    let button = 'mine';
    if (general_filter_value) {
      sessionStorage.setItem(
        'purchase_requisition_list_general_filter',
        general_filter_value,
      );
      const filtersString = general_filter_value.split('&');
      general_filter = filtersString.map(function (filter): FilterParam {
        const filterArray = filter.split('=');
        return {
          key: filterArray[0],
          value: filterArray[1] || '',
        };
      });
      setFilters(general_filter);
    }
    const quickFilters: FilterParam[] = [];
    for (const order in PurchaseRequisitionService.filterButtons) {
      if (
        Object.prototype.hasOwnProperty.call(
          PurchaseRequisitionService.filterButtons,
          order,
        )
      ) {
        const filterStringValue =
          sessionStorage.getItem(
            'purchase_requisition_list_filter_' + order.toString(),
          ) ||
          localStorage.getItem(
            'purchase_requisition_list_filter_' + order.toString(),
          );
        if (filterStringValue) {
          const orderNumber = parseInt(
            order,
          ) as keyof typeof PurchaseRequisitionService.filterButtons;

          if (
            !isNaN(orderNumber) &&
            orderNumber in PurchaseRequisitionService.filterButtons
          ) {
            const filterValue = filterStringValue as keyof typeof PurchaseRequisitionService.filterButtons[typeof orderNumber];
            if (
              order in PurchaseRequisitionService.filterButtons &&
              filterValue in
                PurchaseRequisitionService.filterButtons[orderNumber]
            ) {
              const filter =
                PurchaseRequisitionService.filterButtons[orderNumber][
                  filterValue
                ];

              setFilterState(prevState => ({
                ...prevState,
                [order]: filter,
              }));

              quickFilters.push((filter as unknown) as FilterParam);

              if (orderNumber === 0) {
                button = filterValue;
              }
            }
          }
          sessionStorage.setItem(
            'purchase_requisition_list_filter_' + order.toString(),
            filterStringValue,
          );
        }
      }
    }
    setQuickFilters(quickFilters.flat() as []);
    handleCacheFilters(
      [...quickFilters, ...(general_filter || [])].flat() as [],
      button,
    );
    setcheckCache(true);
  }, []);

  const handleCacheFilters = (filter: FilterParam[], button: string) => {
    setParams(prevParams => ({
      ...prevParams,
      filter: filter,
      group_count: button,
      page: 1,
    }));
  };

  useEffect(() => {
    if (!checkCache) return;
    if (!params.page) params.page = 1;
    setLoading(true);
    PurchaseRequisitionService.getFiltersCount(filters)
      .then(data => {
        setFilterCountGroup(data);
      })
      .catch(error => errorPage(error));

    PurchaseRequisitionService.getList(params)
      .then(data => {
        const results = data.results as Items[];

        if (params.page === 1) {
          setItems(results);
        } else {
          setItems(items => [...items, ...results]);
        }

        setHasNext(data.next != null);
      })
      .catch(error => errorPage(error))
      .finally(() => setLoading(false));
  }, [errorPage, filters, params]);

  useEffect(() => {
    if (params.group_count === 'mine') {
      setFilterCount(filterCountGroup.mine);
    }
    if (params.group_count === 'all') {
      setFilterCount(filterCountGroup.all);
    }
  }, [filterCountGroup]);

  useEffect(() => {
    let firstRegion = null;
    for (const item of items) {
      if (selectedItems.includes(item.id)) {
        if (firstRegion === null) {
          firstRegion = item.branch_region;
        } else if (firstRegion !== item.branch_region) {
          setSelectedItemsSameRegion(false);
          return;
        }
      }
    }
    setSelectedItemsSameRegion(true);
  }, [items, selectedItems]);

  const fetchData = () => {
    if (!hasNext) return;
    setParams(params => ({ ...params, page: params.page + 1 }));
  };
  const fetchSorted = (field: Field) => {
    if (!field.sort) return;

    if (params.sort === field.sort) {
      params.order = params.order === 'asc' ? 'desc' : 'asc';
    } else {
      params.order = 'asc';
      params.sort = field.sort;
    }

    params.page = 1;

    setParams(oldParams => ({ ...oldParams, ...params }));
  };

  type FilterButtons = typeof PurchaseRequisitionService.filterButtons;

  type FilterButtonKeys<T> = T extends Record<string, any> ? keyof T : never;

  const toggleFilterButton = <T extends keyof FilterButtons>(
    button: FilterButtonKeys<FilterButtons[T]>,
    order: T,
  ) => {
    if (
      order in PurchaseRequisitionService.filterButtons &&
      button in PurchaseRequisitionService.filterButtons[order]
    ) {
      const newFilters = {
        ...filterState,
        [order]: PurchaseRequisitionService.filterButtons[order][button],
      };

      localStorage.setItem(
        'purchase_requisition_list_filter_' + order.toString(),
        button,
      );
      sessionStorage.setItem(
        'purchase_requisition_list_filter_' + order.toString(),
        button,
      );
      setFilterState(newFilters);
      const filtersArray = Object.values(newFilters);
      const filter = [...filters, ...(filtersArray.flat() as FilterParam[])];
      setParams(prevParams => ({
        ...prevParams,
        filter: filter,
        group_count: order === 0 ? button : prevParams.group_count,
        page: 1,
      }));
      setQuickFilters([...(filtersArray.flat() as FilterParam[])]);
    }
  };

  const selectOne = (id: number) => {
    setSelectedItems([...selectedItems, id]);
  };

  const removeOne = (id: number) => {
    const filtered = selectedItems.filter(item => {
      return item !== id;
    });
    setSelectedItems(filtered);
  };

  function handleCreateQuote() {
    const newQuote: Items[] = [];
    items.forEach(item => {
      if (selectedItems.includes(item.id)) {
        newQuote.push(item);
      }
    });
    history.push('/quote/new/', {
      selectedItems: newQuote,
      params: 'newQuote',
    });
  }

  function handleMarkResolved() {
    async function updateSelectedItems(): Promise<void> {
      setLoading(true);
      await api
        .post('/purchase_requisitions/mark_resolved/', selectedItems)
        .then(response => {
          if (response.status === 200) {
            setSelectedItems([]);
            const updatedParams = { ...params };
            setParams(updatedParams);
            // setLoading(false);
          }
        })
        // .catch(setResponseError)
        .finally(() => {
          // setLoading(false);
        });
    }

    updateSelectedItems();
  }

  const handleFilterRequest = (newUrl: string) => {
    localStorage.setItem('purchase_requisition_list_general_filter', newUrl);
    sessionStorage.setItem('purchase_requisition_list_general_filter', newUrl);
    const filtersString = newUrl.split('&');
    const filters = filtersString.map(function (filter): FilterParam {
      const filterArray = filter.split('=');
      return {
        key: filterArray[0],
        value: filterArray[1],
      };
    });
    setFilters(filters);
    const filter = [...quickFilters, ...filters];
    setParams(prevParams => ({ ...prevParams, filter: filter, page: 1 }));
  };

  const handleLoadedOptions = (
    items: never[],
    dropdown: string,
    loadedOptions: never[],
  ) => {
    const options: Options[] = formatOptions(items, dropdown, loadedOptions);
    switch (dropdown) {
      case 'num_req':
        setNumReq([...options]);
        break;
      case 'buyer_group_id':
        setBuyerGroups([...options]);
        break;
      case 'merchandise_group':
        setmerchadiseGroups([...options]);
        break;
      case 'material':
        setMaterials([...options]);
        break;
      case 'region':
        setRegions([...options]);
        break;
      case 'urgency':
        setUrgencies([...options]);
        break;
      case 'warehouse':
        setWarehouses([...options]);
        break;
    }
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  function openModal(text: string) {
    setMessage(text);
    setIsOpen(true);
  }

  const handleChangeFile = async (e: any) => {
    const file = e.target.files[0];
    const fileBase64: any = await toBase64(file);
    const csv = fileBase64.split(',')[1];
    let data = csv.split('/')[1];
    if (!data) {
      data = csv;
    }
    const url = 'bulk_import/upload_csv/?model=PurchaseRequisition';

    await api
      .post(url, {
        data: data,
      })
      .then(response => {
        console.log(response);
        openModal('success_upload');
      })
      .catch(error => {
        console.log(error);
        console.log(typeof error);
        if (
          typeof error === 'object' &&
          'response' in error &&
          error.response &&
          'data' in error.response &&
          error.response.data
        ) {
          if (
            'no_field_errors' in error.response.data &&
            error.response.data.no_field_errors.length > 0
          ) {
            openModal(error.response.data.no_field_errors);
          } else if (
            'errorList' in error.response.data &&
            error.response.data.errorList.length > 0
          ) {
            openModal(error.response.data.errorList);
          }
        } else {
          if (typeof error === 'string') {
            openModal(error);
          } else {
            openModal('Unknown Error');
          }
        }
      })
      .finally(() => {
        e.target.value = null;
      });
  };

  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  return (
    <>
      {loading && <Loading />}
      <div className="w-full">
        <div className="flex-1 flex-col">
          <div className="w-full pt-5">
            <CustomComponent>
              <CustomFilterPosition>
                <div className="flex">
                  <div className="flex">
                    <FilterButton
                      buttons={filterButtons[0]}
                      type={'purchase_requisition'}
                      order={0}
                      toogleAction={toggleFilterButton}
                    />
                  </div>
                  <div className="ml-5">
                    <Filters
                      filterFields={filterFields}
                      handleResponse={handleFilterRequest}
                      handleOptions={handleLoadedOptions}
                      type={'purchase_requisition'}
                    />
                  </div>
                </div>
                <div className="flex justify-end w-full">
                  <ListButton
                    keyButtonText={'load_purchaserequisition'}
                    permissionsNeeded={[
                      'add_purchaserequisition',
                      'change_purchaserequisition',
                    ]}
                    onClick={() => inputRef.current.click()}
                    upload
                  />
                  <HiddenFileInput
                    id="upload"
                    ref={inputRef}
                    onChange={handleChangeFile}
                    accept=".csv"
                  />
                  <ListButton
                    keyButtonText={'export_list'}
                    permissionsNeeded={[
                      'add_purchaserequisition',
                      'change_purchaserequisition',
                    ]}
                    toogleAction={PurchaseRequisitionService.exportCsv}
                    params={params}
                  />
                  <GoTopButton content={contentRef.current} />
                </div>
              </CustomFilterPosition>
              <div className="flex w-full mb-3 h-10">
                <FilterButton
                  buttons={filterButtons[1]}
                  type={'purchase_requisition'}
                  order={1}
                  toogleAction={toggleFilterButton}
                />
              </div>
              <div className="flex px-4 w-full justify-between items-center">
                <div className="w-4 h-4" />
                {fields.map((field, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${field.size || 'flex-1'}`}
                  >
                    <div className="flex items-center w-5 h-5">
                      <SortIcon
                        order={
                          params.sort === field.sort
                            ? params.order === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'asc'
                        }
                        onClick={() => fetchSorted(field)}
                      />
                    </div>
                    <span
                      title={`${t(field.label)}`}
                      className="font-default select-none font-bold text-sm text-bluejeans-800 truncate"
                    >
                      {t(field.label)}
                    </span>
                  </div>
                ))}
                <div className="flex h-4 w-4 ml-2" />
              </div>
            </CustomComponent>
            <Content
              ref={contentRef}
              id="scrollableDiv"
              className="flex-1 flex-col overflow-y-auto pl-16"
              style={{ height: '67vh' }}
            >
              {items.length === 0 && (
                <div className="mt-6 flex justify-center items-center">
                  <span className="font-default text-sm font-light italic text-300">
                    {t('empty_list')}
                  </span>
                </div>
              )}
              <InfiniteScroll
                dataLength={items?.length || 0}
                hasMore={hasNext}
                next={fetchData}
                loader={<></>}
                scrollableTarget="scrollableDiv"
              >
                {items.map((item, key) => {
                  const purchaseRequisition = item.item_purchase_requisition;
                  const dt_lib_rc =
                    item.dt_lib_rc || purchaseRequisition.created_on;
                  const FeaturedComponent: React.FC = () => {
                    const labels = [
                      {
                        label: purchaseRequisition.num_req,
                        size: 'md:w-3/36 lg:w-3/36',
                      },
                      {
                        label: item.item_req || '-',
                        size: 'md:w-2/36 lg:w-2/36',
                      },
                      {
                        label: item.material.cod_material,
                        size: 'md:w-2/36 lg:w-2/36',
                      },
                      {
                        label: item.branch_region,
                        size: 'md:w-2/36 lg:w-2/36',
                      },
                      {
                        label: item.warehouse,
                        size: 'md:w-2/36 lg:w-2/36',
                      },
                      {
                        label: item.short_description || item.long_description,
                        size: 'md:w-8/36 lg:w-8/36',
                      },
                      {
                        label: item.merchandise_group.erp_id || '-',
                        size: 'md:w-3/36 lg:w-3/36',
                      },
                      {
                        label: item.urgency_status,

                        size: 'md:w-2/36 lg:w-2/36',
                      },
                      {
                        deadline: {
                          sla_deadline: item.sla_deadline,
                          created_on: dt_lib_rc,
                        },
                        size: 'md:w-3/36 lg:w-3/36',
                      },
                      {
                        label: item.vendor_list ? t('Yes') : t('No'),
                        size: 'md:w-2/36 lg:w-2/36',
                      },
                      {
                        date: true,
                        label: item.dt_delivery
                          ? format(new Date(item.dt_delivery), dateFormat)
                          : '-',
                        size: 'md:w-2/36 lg:w-2/36',
                      },
                      {
                        label: item.status,
                        size: 'md:w-2/36 lg:w-2/36',
                      },
                    ];

                    return (
                      <>
                        {labels.map((item, key) => (
                          <span
                            className={`font-default font-normal text-sm text-yaleblue-900 text-left
                        ${item.size || 'flex-1'}`}
                            key={key}
                          >
                            {item.deadline ? (
                              <div className="flex items-center">
                                <DeadlineDonut
                                  deadlineDate={item.deadline.sla_deadline}
                                  createdAt={dt_lib_rc}
                                />
                              </div>
                            ) : (
                              <div className="truncate" title={`${item.label}`}>
                                {item.date
                                  ? item.label
                                  : removeZerosLeft(item.label)}
                              </div>
                            )}
                          </span>
                        ))}
                      </>
                    );
                  };

                  const ContentComponent: React.FC = () => {
                    const contentItems = [
                      {
                        firstLabel: 'status',
                        firstValue: item.status,
                        secondLabel: 'buyer_group',
                        secondValue: item.buyer_group?.name || '-',
                      },
                      {
                        firstLabel: 'rc_creation_date',
                        firstValue: format(new Date(dt_lib_rc), dateFormat),
                        secondLabel: 'quantity',
                        secondValue: item.quantity,
                      },
                      {
                        customClass: 'overflow-y-auto',
                        firstLabel: 'long_description',
                        firstValue: item.long_description,
                        secondLabel: '',
                        secondValue: null,
                      },
                    ];
                    const lastPurchaseDate = item.last_purchase_date
                      ? format(new Date(item.last_purchase_date), dateFormat)
                      : '-';
                    const lastPurchaseItems = [
                      {
                        firstLabel: 'last_purchase_number',
                        firstValue: item.last_purchase_order_number || '-',
                        secondLabel: 'item',
                        secondValue: item.last_purchase_item,
                      },
                      {
                        firstLabel: 'date',
                        firstValue: lastPurchaseDate,
                        secondLabel: 'supplier',
                        secondValue: item.last_purchase_supplier?.code || '-',
                      },
                      {
                        firstLabel: 'nickname',
                        firstValue:
                          item.last_purchase_supplier?.nickname || '-',
                        secondLabel: 'social_name',
                        secondValue:
                          item.last_purchase_supplier?.social_name || '-',
                      },
                      {
                        firstLabel: 'price',
                        firstValue: formatStringToCurrency(
                          item.last_purchase_price,
                        ),
                        secondLabel: 'by',
                        secondValue: item.last_purchase_vl_priceunit,
                      },
                    ];
                    return (
                      <>
                        <div className="flex flex-row pt-4">
                          <div className="flex w-full">
                            {contentItems.map((item, index) => (
                              <ContentContainer
                                className={`justify-center items-center h-24 mr-4 ${
                                  item.customClass || ''
                                }`}
                                key={`content-${key}-${index}`}
                              >
                                <div className="flex flex-col max-w-sm break-words p-1">
                                  <span className="font-default font-normal text-xs text-yaleblue-1000 mt-4">
                                    {t(item.firstLabel)}
                                  </span>
                                  <div className="font-default font-normal text-sm text-yaleblue-1000">
                                    {item.firstValue}
                                  </div>
                                </div>
                                <div className="flex flex-col max-w-2xl break-words p-1 pt-3">
                                  <span className="font-default font-normal text-xs text-yaleblue-1000">
                                    {t(item.secondLabel)}
                                  </span>
                                  <div className="font-default font-normal text-sm text-yaleblue-1000">
                                    {item.secondValue}
                                  </div>
                                </div>
                              </ContentContainer>
                            ))}
                          </div>
                          <div className="flex w-full justify-between">
                            {lastPurchaseItems.map((item, index) => (
                              <ContentLastPurchase
                                className="justify-center items-center h-24 mr-4"
                                key={`content-${key}-${index}`}
                              >
                                <div className="flex flex-col">
                                  {item.firstLabel ===
                                    'last_purchase_number' && (
                                    <span className="font-default font-normal text-sm text-yaleblue-1000 -mt-5 font-semibold">
                                      {t('last_purchase')}
                                    </span>
                                  )}
                                  <span className="font-default font-normal text-xs text-yaleblue-1000 mt-4">
                                    {t(item.firstLabel)}
                                  </span>
                                  <div className="font-default font-normal text-sm text-yaleblue-1000">
                                    {item.firstLabel === 'date'
                                      ? item.firstValue
                                      : item.firstValue
                                      ? `${removeZerosLeft(
                                          item.firstValue.toString(),
                                        )}`
                                      : `-`}
                                  </div>
                                </div>
                                <div className="flex flex-col mt-8">
                                  <span className="font-default font-normal text-xs text-yaleblue-1000">
                                    {t(item.secondLabel)}
                                  </span>
                                  <div className="font-default font-normal text-sm text-yaleblue-1000">
                                    {item.secondValue
                                      ? `${removeZerosLeft(
                                          item.secondValue.toString(),
                                        )}`
                                      : `-`}
                                  </div>
                                </div>
                              </ContentLastPurchase>
                            ))}
                          </div>
                        </div>
                        <div className="flex w-full justify-end mt-12 mb-4">
                          {item.rfq_id ? (
                            <Link
                              className="underline font-default font-bold text-sm text-bluejeans-800"
                              to={`/quote/show/${item.rfq_id}`}
                            >
                              {t('see_details')}
                            </Link>
                          ) : (
                            <span className="font-default font-bold text-sm text-gray-400 cursor-not-allowed">
                              {t('see_details')}
                            </span>
                          )}
                        </div>
                      </>
                    );
                  };

                  return (
                    <ListItem
                      key={key}
                      FeaturedComponent={FeaturedComponent}
                      ContentComponent={ContentComponent}
                      className={
                        userHasAnyPermissionOf([
                          'add_quotation',
                          'change_quotation',
                        ])
                          ? 'mt-2'
                          : 'pl-8 mt-2'
                      }
                      selectOne={selectOne}
                      removeOne={removeOne}
                      isSelected={selectedItems.includes(item.id)}
                      disabled={item.st_control !== 0}
                      id={item.id}
                      hasCheckbox={userHasAnyPermissionOf([
                        'add_quotation',
                        'change_quotation',
                      ])}
                    />
                  );
                })}
              </InfiniteScroll>
            </Content>
          </div>
          {selectedItems.length > 0 && (
            <div>
              <div className="height pt-8">&nbsp;</div>
              <div className="fixed h-14 w-full bg-yaleblue-700 bottom-0 flex px-28 justify-between items-center">
                <div className="flex items-center">
                  <span className="font-default font-normal text-sm text-white mr-4">
                    {`${selectedItems.length} ${t('requests_selecteds')}`}
                  </span>
                  {!selectedItemsSameRegion && (
                    <span className="font-default font-normal text-sm text-yellow-200">
                      {t('requests_selected_different_regions')}
                    </span>
                  )}
                </div>
                <div className="flex items-center">
                  <Button
                    onClick={() => handleMarkResolved()}
                    className="bg-transparent border-2 px-12 py-2 mr-4 font-default font-bold text-sm text-white"
                  >
                    {t('mark_resolved')}
                  </Button>
                  <Button
                    onClick={() => handleCreateQuote()}
                    disabled={!selectedItemsSameRegion}
                    className="bg-transparent border-2 px-12 py-2 font-default font-bold text-sm text-white"
                  >
                    {t('create_quote')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PurchaseRequisition;
