import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

import { Container, Content, ContentContainer } from './HistoricData.style';
import { useTranslation } from 'react-i18next';
import ListItem from '../../../components/ListItem/ListItem';
import { ReactComponent as UserIcon } from '../../../assets/user-icon.svg';
import { ReactComponent as UsersIcon } from '../../../assets/users-icon.svg';
import { ReactComponent as UserIconSelec } from '../../../assets/user-icon-selec.svg';
import { ReactComponent as UsersIconSelec } from '../../../assets/users-icon-selec.svg';
import Loading from '../../../components/Loading/Loading';
import ListButton from '../../../components/ListButton/ListButton';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import FilterButton from '../../../components/FilterButton/FiltterButton';
import HistoricDataService, {
  HistoricDataFilterCounter,
  HistoricDataFilterCounterGroup,
} from '../../../services/historic-data-service';
import Filters from '../../../components/Filters/Filters';
import { formatOptions } from '../../../helpers/OptionsHelpers/FormatOptions';
import GoTopButton from '../../../shared/GoTopButton/GoTopButton';
import { FilterParam, ListParams } from '../../../services/base-service';
import SortIcon from '../../../shared/SortIcon/SortIcon';
import { HISTORIC_DATA_STATUS } from '../../../utils/rfqStatusControl';
import { CustomComponent, CustomFilterPosition } from '../../../styled';

interface HistoricData {
  rfq_id: number;
  purchase_number: string;
  supplier_number: string;
  supplier_name: string;
  buyer: string;
  rounds: number;
  order_date: string;
  total_value: number;
  status: string;
  sla: string;
  saving_value: number;
  saving_percent: number;
  items: Items[];

  [index: string]: string | number | Items[];
}

interface Items {
  purchase_requisition: string;
  item_requisition: number;
  buyer: string;
  material: string;
  description: string;
  merchandise_group: string;
  urgency: string;
  center: string;
  quantity: number;

  [index: string]: string | number;
}

interface HistoricDataProps {
  totalHistoricData: number;
  errorPage: Function;
}

interface Options {
  label: string;
  value: string | number;
}

interface Field {
  label: string;
  sort: string;
  value?: string;
  size?: string;
  type?: string;
}

const HistoricData: React.FC<HistoricDataProps> = ({ errorPage }) => {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState<Options[]>([]);
  const [codRc, setCodRc] = useState<Options[]>([]);
  const [buyerGroups, setBuyerGroups] = useState<Options[]>([]);
  const [materials, setMaterial] = useState<Options[]>([]);
  const [regions, setRegions] = useState<Options[]>([]);
  const [numRfqs, setNumRfqs] = useState<Options[]>([]);
  const [numQuotations, setNumQuotations] = useState<Options[]>([]);
  const [iaStatusResult, setIaStatusResult] = useState<Options[]>([]);
  const [iaDetail, setIaDetail] = useState<Options[]>([]);
  const [urgencies, setUrgencies] = useState<Options[]>([]);
  const [loading, setLoading] = useState(true);

  const [historicData, setHistoricData] = useState<HistoricData[]>([]);

  const [params, setParams] = useState<ListParams>(
    HistoricDataService.paramsDefault,
  );
  const [hasNext, setHasNext] = useState(true);

  const [filterCount, setFilterCount] = useState<HistoricDataFilterCounter>(
    HistoricDataService.filterCountDefault,
  );

  const [
    filterCountGroup,
    setFilterCountGroup,
  ] = useState<HistoricDataFilterCounterGroup>(
    HistoricDataService.filterCountGroupDefault,
  );
  const [filters, setFilters] = useState<FilterParam[]>([]);

  const [quickFilters, setQuickFilters] = useState<FilterParam[]>([]);
  interface FilterState {
    [key: string]: FilterParam[];
  }

  const initialFilters: FilterState = {
    '0': HistoricDataService.filterButtons[0].mine,
    '1': HistoricDataService.filterButtons[1].all,
  };

  const [filterState, setFilterState] = useState<FilterState>(initialFilters);
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const fields: Field[] = [
    {
      label: 'purchase_document',
      value: 'purchase_number',
      type: 'number',
      sort: 'num_rfq',
    },
    {
      label: 'suppliers_number',
      value: 'supplier_number',
      type: 'number',
      size: 'lg:w-24 md:w-24',
      sort: 'awarded_items__supplier__code',
    },
    {
      label: 'supplier_name',
      value: 'supplier_name',
      sort: 'awarded_items__supplier__social_name',
    },
    {
      label: 'buyer',
      value: 'buyer',
      sort: 'grp_purchasing__name',
    },
    {
      label: 'rounds',
      value: 'rounds',
      type: 'number',
      sort: 'rounds',
    },
    {
      label: 'request_date',
      value: 'order_date',
      type: 'date',
      sort: 'awarded_items__created_on',
    },
    {
      label: 'total_value',
      value: 'total_value',
      type: 'number',
      sort: 'total_value',
    },
    {
      label: 'status',
      value: 'status',
      size: 'lg:w-20 md:w-20',
      sort: 'status',
    },
    { label: 'sla_deadline', value: 'sla', type: 'number', sort: 'sla' },
    {
      label: 'saving',
      value: 'saving_value',
      type: 'number',
      sort: 'saving_value',
    },
    {
      label: 'saving_percent',
      value: 'saving_percent',
      type: 'number',
      sort: 'saving_percent',
    },
  ];
  const filterFields = {
    fieldsList: [
      {
        field: 'cod_rc',
        type: 'list',
        placeholder: 'Ex. 18831544',
        url: 'purchase_requisitions/list_to_filter/?num_req=',
        options: codRc,
      },
      {
        field: 'buyer_group_id',
        type: 'list',
        placeholder: 'select_buyer_group',
        url: 'buyer_groups/list_to_filter/?name=',
        options: buyerGroups,
      },
      {
        field: 'material',
        type: 'list',
        placeholder: 'Ex. 175239',
        url: 'materials/list_to_filter/?cod_material=',
        options: materials,
      },
      {
        field: 'urgency',
        type: 'select',
        placeholder: 'select_urgency',
        options: urgencies,
        url: 'sla/list_to_filter/?code=',
      },
      {
        field: 'region',
        type: 'list',
        placeholder: 'select_region',
        url: 'centers/list_to_filter/?name=',
        options: regions,
      },
      {
        field: 'deadline',
        type: 'date',
        placeholder: '00/00/0000',
      },
      {
        field: 'suppliers',
        type: 'select',
        placeholder: 'select_supplier',
        options: suppliers,
        url: 'suppliers/list_to_filter/?supplier=',
      },
      {
        field: 'num_rfq',
        type: 'list',
        placeholder: 'Ex. G950264',
        options: numRfqs,
        url: 'rfqs/list_to_filter/?num_rfq=',
      },
      {
        field: 'num_quotation',
        type: 'list',
        placeholder: 'Ex. 6004045418',
        options: numQuotations,
        url: 'quotation_suppliers/list_to_filter/?cod_quotation=',
      },
      {
        field: 'ia_status_result',
        type: 'list',
        options: iaStatusResult,
        url: 'rfqs/list_to_filter/?ia_status_result_code=',
      },
      {
        field: 'ia_detail',
        type: 'list',
        options: iaDetail,
        url: 'rfqs/list_to_filter/?ia_status_result=',
      },
      {
        field: 'is_automation',
        type: 'select',
        placeholder: 'select_type',
        options: [
          {
            label: 'sim',
            value: 'True',
          },
          {
            label: 'não',
            value: 'False',
          },
        ],
      },
      {
        field: 'st_control',
        type: 'select',
        placeholder: 'select_st_control',
        options: Object.entries(HISTORIC_DATA_STATUS).map(([key, value]) => ({
          label: t(key),
          value: value,
        })),
      },
    ],
    hasSla: false,
  };

  const filterButtons = {
    0: [
      {
        button: 'mine',
        keyText: `${t('my_plural')} (${filterCountGroup.mine.total})`,
        icon: UserIcon,
        iconSelec: UserIconSelec,
      },
      {
        button: 'all',
        keyText: `${t('all')} (${filterCountGroup.all.total})`,
        icon: UsersIcon,
        iconSelec: UsersIconSelec,
      },
    ],
    1: [
      {
        button: 'all',
        keyText: `${t('all')} (${filterCount.total})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'attended_ia',
        keyText: `${t('attended_ai')} (${filterCount.created_ia})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'created_manually',
        keyText: `${t('created_manually')} (${filterCount.manual})`,
        icon: null,
        iconSelec: null,
      },
    ],
  };

  const emptyItem: Items = {
    purchase_requisition: '',
    item_requisition: 0,
    buyer: '',
    material: '',
    description: '',
    merchandise_group: '',
    urgency: '',
    center: '',
    quantity: 0,
  };

  const [checkCache, setcheckCache] = useState(false);

  useEffect(() => {
    if (!checkCache) return;
    if (!params.page) params.page = 1;
    HistoricDataService.getFiltersCount(filters)
      .then(data => setFilterCountGroup(data))
      .catch(error => {
        errorPage(error);
      });
  }, [checkCache, errorPage, filters, params]);

  // Fetch Count
  useEffect(() => {
    if (params.group_count === 'mine') {
      setFilterCount(filterCountGroup.mine);
    }
    if (params.group_count === 'all') {
      setFilterCount(filterCountGroup.all);
    }
  }, [filterCountGroup]);

  useLayoutEffect(() => {
    const general_filter_value =
      sessionStorage.getItem('historic_data_list_general_filter') ||
      localStorage.getItem('historic_data_list_general_filter');
    let general_filter;
    let button = 'mine';
    if (general_filter_value) {
      sessionStorage.setItem(
        'historic_data_list_general_filter',
        general_filter_value,
      );
      const filtersString = general_filter_value.split('&');
      general_filter = filtersString.map(function (filter): FilterParam {
        const filterArray = filter.split('=');
        return {
          key: filterArray[0],
          value: filterArray[1] || '',
        };
      });
      setFilters(general_filter);
    }
    const quickFilters: FilterParam[] = [];
    for (const order in HistoricDataService.filterButtons) {
      if (
        Object.prototype.hasOwnProperty.call(
          HistoricDataService.filterButtons,
          order,
        )
      ) {
        const filterStringValue =
          sessionStorage.getItem(
            'historic_data_list_filter_' + order.toString(),
          ) ||
          localStorage.getItem('historic_data_list_filter_' + order.toString());
        if (filterStringValue) {
          const orderNumber = parseInt(
            order,
          ) as keyof typeof HistoricDataService.filterButtons;

          if (
            !isNaN(orderNumber) &&
            orderNumber in HistoricDataService.filterButtons
          ) {
            const filterValue = filterStringValue as keyof typeof HistoricDataService.filterButtons[typeof orderNumber];
            if (
              order in HistoricDataService.filterButtons &&
              filterValue in HistoricDataService.filterButtons[orderNumber]
            ) {
              const filter =
                HistoricDataService.filterButtons[orderNumber][filterValue];

              setFilterState(prevState => ({
                ...prevState,
                [order]: filter,
              }));

              quickFilters.push((filter as unknown) as FilterParam);

              if (orderNumber === 0) {
                button = filterValue;
              }
            }
          }
          sessionStorage.setItem(
            'historic_data_list_filter_' + order.toString(),
            filterStringValue,
          );
        }
      }
    }
    setQuickFilters(quickFilters.flat() as []);
    handleCacheFilters(
      [...quickFilters, ...(general_filter || [])].flat() as [],
      button,
    );
    setcheckCache(true);
  }, []);

  const handleCacheFilters = (filter: FilterParam[], button: string) => {
    setParams(prevParams => ({
      ...prevParams,
      filter: filter,
      group_count: button,
      page: 1,
    }));
  };
  // Fetch Historic Data
  useEffect(() => {
    if (!checkCache) return;
    if (!params.page) params.page = 1;
    setLoading(true);
    HistoricDataService.getList(params)
      .then(data => {
        const results = data.results as HistoricData[];

        if (params.page === 1) {
          setHistoricData(results);
        } else {
          setHistoricData(historicData => [...historicData, ...results]);
        }

        setHasNext(data.next != null);
      })
      .catch(error => errorPage(error))
      .finally(() => setLoading(false));
  }, [errorPage, filters, params]);

  const fetchNextPage = () => {
    if (!hasNext) return;
    setParams(params => ({ ...params, page: params.page + 1 }));
  };

  const fetchSorted = async (field: Field) => {
    if (!field.sort) return;

    if (params.sort === field.sort) {
      params.order = params.order === 'asc' ? 'desc' : 'asc';
    } else {
      params.order = 'asc';
      params.sort = field.sort;
    }

    params.page = 1;

    setParams(oldParams => ({ ...oldParams, ...params }));
  };

  type FilterButtons = typeof HistoricDataService.filterButtons;

  type FilterButtonKeys<T> = T extends Record<string, any> ? keyof T : never;
  const toggleFilterButton = <T extends keyof FilterButtons>(
    button: FilterButtonKeys<FilterButtons[T]>,
    order: T,
  ) => {
    if (
      order in HistoricDataService.filterButtons &&
      button in HistoricDataService.filterButtons[order]
    ) {
      const newFilters = {
        ...filterState,
        [order]: HistoricDataService.filterButtons[order][button],
      };

      localStorage.setItem(
        'historic_data_list_filter_' + order.toString(),
        button,
      );
      sessionStorage.setItem(
        'historic_data_list_filter_' + order.toString(),
        button,
      );
      setFilterState(newFilters);
      const filtersArray = Object.values(newFilters);
      const filter = [...filters, ...(filtersArray.flat() as FilterParam[])];
      setParams(prevParams => ({
        ...prevParams,
        filter: filter,
        group_count: order === 0 ? button : prevParams.group_count,
        page: 1,
      }));
      setQuickFilters([...(filtersArray.flat() as FilterParam[])]);
    }
  };

  const handleFilterRequest = (newUrl: string) => {
    localStorage.setItem('historic_data_list_general_filter', newUrl);
    sessionStorage.setItem('historic_data_list_general_filter', newUrl);
    const filtersString = newUrl.split('&');
    const filters = filtersString.map(function (filter): FilterParam {
      const filterArray = filter.split('=');
      return {
        key: filterArray[0],
        value: filterArray[1] || '',
      };
    });
    setFilters(filters);
    const filter = [...filters, ...quickFilters];
    setParams(prevParams => ({ ...prevParams, filter: filter, page: 1 }));
  };

  const handleLoadedOptions = (
    items: never[],
    dropdown: string,
    loadedOptions: never[],
  ) => {
    const options: Options[] = formatOptions(items, dropdown, loadedOptions);

    switch (dropdown) {
      case 'suppliers':
        setSuppliers([...options]);
        break;
      case 'cod_rc':
        setCodRc([...options]);
        break;
      case 'buyer_group_id':
        setBuyerGroups([...options]);
        break;
      case 'material':
        setMaterial([...options]);
        break;
      case 'region':
        setRegions([...options]);
        break;
      case 'num_rfq':
        setNumRfqs([...options]);
        break;
      case 'ia_status_result':
        setIaStatusResult([...options]);
        break;
      case 'ia_detail':
        setIaDetail([...options]);
        break;
      case 'num_quotation':
        setNumQuotations([...options]);
        break;
      case 'urgency':
        setUrgencies([...options]);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="w-full">
        <Container className="flex-1 flex-col">
          <div className="flex w-full justify-center">
            <div className="w-full pt-5">
              <CustomComponent>
                <CustomFilterPosition>
                  <FilterButton
                    buttons={filterButtons[0]}
                    type={'historic_data'}
                    order={0}
                    toogleAction={toggleFilterButton}
                  />
                  <div className="flex ml-5">
                    <FilterButton
                      buttons={filterButtons[1]}
                      type={'historic_data'}
                      order={1}
                      toogleAction={toggleFilterButton}
                    />
                  </div>
                  <div className="ml-5">
                    <Filters
                      filterFields={filterFields}
                      handleResponse={handleFilterRequest}
                      className={'right-0'}
                      handleOptions={handleLoadedOptions}
                      type={'historic_data'}
                    />
                  </div>
                  <div className="flex justify-end w-full">
                    <ListButton
                      keyButtonText={'export_list'}
                      permissionsNeeded={['add_rfq', 'change_rfq']}
                      toogleAction={HistoricDataService.exportCsv}
                      params={params}
                    />
                  </div>
                  {historicData.length > 0 && (
                    <GoTopButton content={contentRef.current} />
                  )}
                </CustomFilterPosition>
                <div className="flex w-full justify-between items-center px-4">
                  {fields.map((field, index) => (
                    <div
                      key={index}
                      className={`flex items-center px-1 flex-1`}
                    >
                      <SortIcon
                        order={
                          params.sort === field.sort
                            ? params.order === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'asc'
                        }
                        onClick={() => fetchSorted(field)}
                      />
                      <span className="font-default select-none font-bold text-sm text-bluejeans-800 ml-2">
                        {t(field.label)}
                      </span>
                    </div>
                  ))}
                  <div className="flex h-4 w-4 ml-2" />
                </div>
              </CustomComponent>
              <Content
                id="scrollableDiv"
                ref={contentRef}
                className="overflow-y-auto pl-16"
                style={{ height: '74vh' }}
              >
                {historicData.length === 0 && (
                  <div className="mt-6 flex justify-center items-center">
                    <span className="font-default text-sm font-light italic text-300">
                      {t('empty_list')}
                    </span>
                  </div>
                )}
                <InfiniteScroll
                  dataLength={historicData.length}
                  hasMore={hasNext}
                  next={fetchNextPage}
                  loader={<></>}
                  scrollableTarget="scrollableDiv"
                >
                  {historicData &&
                    historicData.map((item, key) => {
                      const FeaturedComponent: React.FC = () => {
                        const labels = [
                          { label: item.purchase_number },
                          {
                            label: item.supplier_number,
                            size: 'lg:w-24 md:w-24',
                          },
                          { label: item.supplier_name },
                          { label: item.buyer },
                          { label: item.rounds, size: 'lg:w-20 md:w-20' },
                          { label: item.order_date },
                          {
                            label: item.total_value.toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              style: 'currency',
                              currency: 'BRL',
                            }),
                          },
                          { label: item.status, size: 'lg:w-20 md:w-20' },
                          { label: item.sla },
                          {
                            label: item.saving_value.toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              style: 'currency',
                              currency: 'BRL',
                            }),
                          },
                          {
                            label: item.saving_percent.toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              style: 'percent',
                            }),
                          },
                        ];

                        return (
                          <>
                            {labels.map((item, key) => (
                              <span
                                className={`font-default font-normal text-sm text-yaleblue-900 text-left truncate flex-1 px-2`}
                                key={key}
                              >
                                <span title={item.label?.toString() || '-'}>
                                  {item.label || `-`}
                                </span>
                              </span>
                            ))}
                          </>
                        );
                      };
                      const ContentComponent: React.FC = () => {
                        return (
                          <>
                            <div className="flex flex-row">
                              {Object.entries(item.items[0] ?? emptyItem).map(
                                (entry, contentKey) => (
                                  <ContentContainer
                                    key={contentKey}
                                    className="flex justify-center items-start w-full flex-col"
                                  >
                                    <span className="font-default font-normal text-xs text-yaleblue-1000">
                                      {t(entry[0])}
                                    </span>
                                  </ContentContainer>
                                ),
                              )}
                            </div>
                            {item.items.map((content, key) => (
                              <div key={key} className="flex flex-row w-full">
                                {Object.entries(content).map((entry, key) => (
                                  <ContentContainer
                                    key={key}
                                    className="flex text-left w-full flex-col font-default font-normal text-sm text-yaleblue-1000"
                                  >
                                    {entry[1] || `-`}
                                  </ContentContainer>
                                ))}
                              </div>
                            ))}
                            <div className="flex w-full justify-end mt-12 mb-4">
                              <Link
                                className="underline font-default font-bold text-sm text-bluejeans-800"
                                to={`quote/show/${item.rfq_id}`}
                              >
                                {t('rfq_details')}
                              </Link>
                              &nbsp;&nbsp;
                              <Link
                                className="underline font-default font-bold text-sm text-bluejeans-800"
                                to="#"
                              >
                                {t('see_details')}
                              </Link>
                            </div>
                          </>
                        );
                      };
                      return (
                        <ListItem
                          key={key}
                          FeaturedComponent={FeaturedComponent}
                          ContentComponent={ContentComponent}
                          className="mt-2"
                          id={key}
                          hasCheckbox={false}
                        />
                      );
                    })}
                </InfiniteScroll>
              </Content>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HistoricData;
