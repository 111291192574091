/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';

import { Container, ContentContainer } from './Requote.style';
import './Requote.css';

import Navbar from '../../../components/Navbar/navbar';
import ListItem from '../../../components/ListItem/ListItem';
import DeadlineDonut from '../../../components/DeadlineDonut/DeadlineDonut';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput';
import Input from '../../../components/Input/Input';
import SupplierCard from '../../../components/SupplierCard/SupplierCard';
import AppliedFilters from '../../../components/AppliedFilters/AppliedFilter';
import DatePicker from '../../../components/DatePicker/DatePicker';
import TimePicker from '../../../components/TimePicker/TimePicker';
import Button from '../../../components/Button/Button';
import CustomDropDown from '../../../components/CustomDropdown/CustomDropdown';
import MultiInput from '../../../components/MultiInput/MultiInput';

import { ReactComponent as IconBreadcrumb } from '../../../assets/icon-breadcrumb.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon-blue.svg';
import { ReactComponent as ChavronUpIcon } from '../../../assets/icon-chavron-down.svg';
import { ReactComponent as ChavronDownIcon } from '../../../assets/icon-chavron-up.svg';
import { ReactComponent as SortDescIcon } from '../../../assets/icon-sort-desc.svg';
import { ReactComponent as SortAscIcon } from '../../../assets/icon-sort-asc.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { ReactComponent as DollarSignIcon } from '../../../assets/dollar-sign-icon.svg';
import { ReactComponent as WatchIcon } from '../../../assets/watch-icon.svg';
import { ReactComponent as AddIcon } from '../../../assets/add-icon.svg';
import { ReactComponent as PaperIcon } from '../../../assets/paper-clip-icon.svg';
import { ReactComponent as ConfirmationIcon } from '../../../assets/confirmation-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as AlertIcon } from '../../../assets/alert-icon.svg';
import { ReactComponent as CheckBoxChecked } from '../../../assets/checkbox-checked-icon.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../../assets/checkbox-unchecked-icon.svg';
import Loading from '../../../components/Loading/Loading';
import api from '../../../services/api';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';
import { getEmptyRequiredInputs } from '../../../utils/getEmptyRequiredInputs';
import removeZerosLeft from '../../../utils/removeZerosLeft';
import formatValue from '../../../utils/formatValue';
import { AxiosError } from 'axios';
import { loadSuggestions, addItem } from '../utils';

interface SortFieldProps {
  name: string;
  value: string;
}

interface ItemProps {
  id: number;
  item_req: string;
  target: string;
  observation: string;
  quantity: number;
  material: MaterialProps;
  dt_lib_rc: string;
  item_purchase_requisition: ItemPurchaseRequisitionProps;
  short_description: string;
  long_description: string;
  sla_deadline: string;
  created_on: string;
  is_active: boolean;
  lead_time: string;
  urgency_status: string;
  mercadory_category: string;
  warehouse: string;
  status: string;
  st_control: number;
  branch_region: string;
  dt_delivery: Date;
  last_purchase_item: string;
  last_purchase_price: string;
  last_purchase_date: string;
  last_purchase_supplier: SupplierProps;
  last_purchase_minimum_lot: string;
  last_purchase_order_number: string;
  last_purchase_vl_priceunit: string;
  buyer_group: BuyerGroupProps;
  vendor_list: string;

  payment_condition: PaymentOptionProps;

  [key: string]:
    | number
    | string
    | Date
    | BuyerGroupProps
    | ItemPurchaseRequisitionProps
    | boolean
    | SupplierProps
    | MaterialProps
    | PaymentOptionProps;
}

interface MaterialProps {
  id: number;
  cod_material: string;
  description: string;
}

interface ItemPurchaseRequisitionProps {
  id: number;
  num_req: string;
  is_vendor_list: boolean;
  dt_lib_rc: string;
  created_on: string;
}

interface SupplierProps {
  id: number;
  code: string;
  social_name: string;
  nickname: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  PMP: number;
  saving_percent: number;
  selected: boolean;

  [key: string]: number | string | boolean;
}

interface BuyerGroupProps {
  id: number;
  name: string;
  description: string;
}

interface cardContent {
  id: number;
  nickname: string;
  code: string;
  social_name: string;
  document_number: string;
  uf: string;
  country: string;
  score_serasa: number;
  payment_deadline: number;
  fqi: number;
  saving_percent: number;
}

interface PaymentOptionProps {
  label: string;
  code: string;
}

interface MultiInputOptions {
  id: number;
  value: string;
}

const Requote: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [requestIsOpen, setRequestIsOpen] = useState(true);
  const [items, setItems] = useState<ItemProps[]>([]);
  const [sortField, setSortField] = useState<SortFieldProps>({
    name: '',
    value: '',
  });
  const [suppliers, setSuppliers] = useState<SupplierProps[]>([]);
  const [suggestions, setSuggestions] = useState<SupplierProps[]>([]);
  const [suppliersOptions, setSuppliersOptions] = useState<MultiInputOptions[]>(
    [],
  );
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    MultiInputOptions[]
  >([]);
  const [supplierCardContent, setSupplierCardContent] = useState<cardContent[]>(
    [],
  );
  const [dtDelivery, setDtDelivery] = useState<Date | null>(null);
  const [supplierObservation, setSupplierObservation] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionProps[]>(
    [],
  );
  const [createVendorList, setCreateVendorList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [responseError, setResponseError] = useState<AxiosError | null>();
  const [inputsWithErrors, setInputsWithErrors] = useState<Array<string>>([]);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  const { register, handleSubmit } = useForm();

  const location = useLocation<{
    items: number[];
    suppliers: number[];
  }>();

  const fields = [
    { label: 'requisition' },
    { label: 'item' },
    { label: 'material' },
    { label: 'description' },
    { label: 'region' },
    { label: 'warehouse' },
    { label: 'urgency' },
    { label: 'sla_deadline' },
    { label: 'remittance_dt', size: 'w-2/12' },
    { label: 'target', size: 'w-2/12' },
    { label: 'lead_time' },
  ];

  const onSubmit = () => {
    handleSubmitForm();
  };

  const handleVendorList = () => {
    setCreateVendorList(!createVendorList);
  };

  const handleSupplierCard = (supplier: SupplierProps): cardContent => {
    return {
      id: supplier.id,
      nickname: supplier.nickname || '-',
      code: supplier.code || '-',
      social_name: supplier.social_name || '-',
      document_number: supplier.document_number || '-',
      uf: supplier.state || '-',
      country: supplier.country || '-',
      score_serasa: supplier.score_serasa || 0,
      payment_deadline: supplier.PMP || 0,
      fqi: supplier.quality_percent || 0,
      saving_percent: supplier.saving_percent || 0,
    };
  };

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  useEffect(() => {
    loadSuggestions(items, setSuggestions);
  }, [items]);

  useEffect(() => {
    async function loadComparativeMapItems(
      quoteId: Number,
    ): Promise<ItemProps[]> {
      return api
        .get(`/quotations/${quoteId}/comparative_map/`)
        .then(response => {
          return response.data.items;
        })
        .catch(setResponseError);
    }

    async function loadItems(): Promise<[ItemProps[], Number]> {
      let items: ItemProps[] = [];
      let quoteId: Number = 0;
      try {
        const response = await api.get(`/rfqs/${id}/`);
        const rfq = response.data;
        quoteId = rfq.last_quotation_id;
        items = rfq.item_req_list;

        const responsePaymentCondition = await api.get(`/payment_condition/`);
        handleLoadedPaymentMethod(
          responsePaymentCondition.data.results,
          'payment_method',
          [],
        );
        handlePaymentMethod(items[0].payment_condition?.code);

        const selectedItems = location.state.items;
        items = items.filter((ir: ItemProps) => selectedItems.includes(ir.id));
        items.forEach(item => {
          if (!item.dt_delivery) {
            item.dt_delivery = new Date(item.dt_delivery);
          }
        });
        setItems(items);
        const responseSuppliers = await api.get('/suppliers/');
        const { results } = responseSuppliers.data;
        const suppliers: SupplierProps[] = results;
        const cards: cardContent[] = [];

        if (location.state.suppliers && location.state.suppliers.length > 0) {
          location.state.suppliers.forEach(async suppCode => {
            const indexSuppliers = suppliers.findIndex(
              sup => sup.id === suppCode,
            );
            let supplier: SupplierProps;
            if (indexSuppliers < 0) {
              const resp = await api.get(`/suppliers/${suppCode}/`);
              supplier = resp.data;
            } else {
              supplier = suppliers[indexSuppliers];
            }
            cards.push(handleSupplierCard(supplier));

            const selectedSuppliers = [];
            selectedSuppliers.push({
              id: supplier.id,
              value: supplier.social_name,
            });
            // setSupplierCardContent([
            //   ...supplierCardContent,
            //   handleSupplierCard(supplier),
            // ]);
            setSelectedSuppliers([...selectedSuppliers]);
          });
        }
        setSupplierCardContent(cards);
        setSuppliers(suppliers);
      } catch (error) {
        console.log(error);
      }
      return [items, quoteId];
    }
    (async () => {
      showLoader(true);
      const [items, quoteId] = await loadItems();
      const comparativeMapItems = await loadComparativeMapItems(quoteId);
      items.forEach(item => {
        comparativeMapItems.forEach(comparativeMapItem => {
          if (comparativeMapItem.item_id === item.id) {
            item.target = getCurrencyValue(
              comparativeMapItem.item_target_value + '',
            );
          }
        });
      });
      setItems(items);
      showLoader(false);
    })();
  }, [id, location]);

  const toggleSort = (field: string) => {
    const sort = sortField.value === 'asc' ? 'desc' : 'asc';
    if (sortField.name === field) {
      setSortField({ name: field, value: sort });
    } else {
      setSortField({ name: field, value: 'asc' });
    }
    if (sort === 'asc') {
      items.sort((a, b) => (b[field] > a[field] ? 1 : -1));
    } else {
      items.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    }
  };

  function handleChangeDate(date: Date, item: ItemProps) {
    const newItems = items.slice();
    const index = newItems.findIndex(it => it.id === item.id);
    item.dt_delivery = date;
    newItems[index] = item;
    setItems(newItems);
  }

  function handleIncludeObservation(
    event: React.ChangeEvent<HTMLInputElement>,
    item: ItemProps,
  ) {
    item.observation = event.target.value;
  }

  const handleSelectSupplier = (option: string) => {
    const supIndex = suppliers.findIndex(
      supplier => supplier.code + ': ' + supplier.social_name === option,
    );
    const supplier = suppliers[supIndex];
    const index = suggestions.findIndex(
      suggestion => suggestion.id === supplier.id,
    );
    if (index >= 0) suggestions.splice(index, 1);
    let addNewSupplier = true;
    supplierCardContent.forEach(item => {
      if (item.id === supplier.id) {
        addNewSupplier = false;
      }
    });

    if (addNewSupplier) {
      supplier.selected = true;
      setSupplierCardContent([
        ...supplierCardContent,
        handleSupplierCard(supplier),
      ]);
      selectedSuppliers.push({ id: supplier.id, value: supplier.social_name });
      setSelectedSuppliers([...selectedSuppliers]);
    }
  };

  const handleBackToPanel = () => {
    history.goBack();
  };

  function handleUnselectSupplier(content: cardContent) {
    const supplierIndex = suppliers.findIndex(sup => sup.id === content.id);
    const supplier = suppliers[supplierIndex];
    if (supplier) supplier.selected = false;

    const newSupplierCardContent = supplierCardContent.slice();

    const contentIndex = newSupplierCardContent.findIndex(
      cont => cont.id === content.id,
    );

    newSupplierCardContent.splice(contentIndex, 1);
    setSupplierCardContent(newSupplierCardContent);
    const selecteds = selectedSuppliers.filter(
      sup => sup.id !== suppliers[supplierIndex]?.id,
    );
    setSelectedSuppliers([...selecteds]);
  }

  function handlePaymentMethod(option: string) {
    if (option) {
      const op = option.split('-');
      setPaymentMethod(op[0].trim());
    }
  }

  function handleSupplierObservation(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setSupplierObservation(event.target.value);
  }

  const getCurrencyValue = (value: string): string => {
    if (!value) return '';
    value = value.toString().replace('R$', '');
    if (value.includes(',') && !value.includes('.')) {
      return value;
    }
    if (value.includes('.') && !value.includes(',')) {
      return value.replace('.', ',');
    }
    const val = Number.parseFloat(value.replace('.', '').replace(',', '.'));
    return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  };

  async function handleSubmitForm() {
    const errors: string[] = [];
    const newSuppliers = supplierCardContent.map(item => ({ id: item.id }));
    const newItems = items.map(item => ({
      item_id: item.id,
      observation: item.observation || '',
      dt_delivery: item.dt_delivery || null,
      lead_time: item.lead_time || 0,
      target: getCurrencyValue(item.target),
      selected: true,
    }));

    if (newItems.filter(item => !item.dt_delivery).length) {
      errors.push('items_deadline_null');
    }

    if (dtDelivery === null) {
      errors.push('deadline_null_error');
    }
    if (newSuppliers.length === 0) {
      errors.push('suppliers_null_error');
    }
    if (newItems.length === 0) {
      errors.push('items_null_error');
    }

    if (!paymentMethod) {
      errors.push('payment_method_null_error');
    }

    setValidationErrors(errors);

    const paymentOption = paymentOptions.find(
      payment => payment.code === paymentMethod,
    );
    const payload = {
      rfq_id: id,
      deadline: format(dtDelivery || new Date(), 'yyyy-MM-dd HH:mm:ss'),
      supplier_observation: supplierObservation,
      suppliers: newSuppliers,
      item_rfq: newItems,
      cod_payterm: paymentOption?.code,
      create_vendor_list: createVendorList,
    };

    const emptyRequiredInputs = getEmptyRequiredInputs([
      {
        fieldObject: dtDelivery,
        fieldName: 'dtDelivery',
      },
      {
        fieldObject: newSuppliers,
        fieldName: 'newSuppliers',
      },
      {
        fieldObject: newItems,
        fieldName: 'newItems',
      },
      {
        fieldObject: paymentMethod,
        fieldName: 'paymentMethod',
      },
    ]);

    setInputsWithErrors(emptyRequiredInputs);
    const hasEmptyRequiredInputs = emptyRequiredInputs.length > 0;

    async function createRequote(): Promise<void> {
      if (hasEmptyRequiredInputs) return setErrorModalIsOpen(true);

      showLoader(true);
      await api
        .post('/quotations/add_with_relations/', payload)
        .then(response => {
          if (response.status === 201) {
            openModal();
          }
          showLoader(false);
        })
        .catch(setResponseError)
        .finally(() => {
          showLoader(false);
        });
    }

    createRequote();
  }

  function closeErrorModal() {
    setErrorModalIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    history.push(`/quote/show/${id}`);
  }

  const handleLoadedPaymentMethod = (
    items: never[],
    name: string,
    loadedOptions: never[],
  ) => {
    const payments: PaymentOptionProps[] = [];

    if (loadedOptions) {
      loadedOptions.forEach(item => {
        payments.push(item);
      });
    }

    items.forEach((item: { description: string; code: string }) => {
      const newPayment = {
        label: item.description,
        code: item.code,
      };
      payments.push(newPayment);
    });
    setPaymentOptions([...payments]);
  };

  const handleLoadedSuppliers = (
    items: SupplierProps[],
    loadedOptions: never[],
  ) => {
    const options: MultiInputOptions[] = [];
    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach(item => {
      const newSupplier = {
        id: item.id,
        value: item.code + ': ' + item.social_name,
      };
      options.push(newSupplier);
      const supIndex = suppliers.findIndex(sup => sup.id === item.id);
      if (supIndex < 0) {
        suppliers.push(item);
      }
    });
    setSuppliersOptions([...options]);
    setSuppliers([...suppliers]);
  };

  const closeErrorPage = () => setResponseError(null);

  return (
    <div>
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={closeErrorPage}
        />
      ) : (
        <div>
          <Container className="flex-1 flex-col bg-primary">
            {loading && <Loading />}
            <Navbar pageTitle={''} selectedMenu="panel" />
            <div className="flex items-center px-12 xl:px-20">
              <span className="font-default font-normal text-white text-sm">
                {t('purchase_panel')}
              </span>
              <IconBreadcrumb className="h-2 w-2 mx-4" />
              <span className="font-default font-bold text-white text-sm">
                {t('new_requote')}
              </span>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex overflow-y-auto w-full px-12 xl:px-20 justify-center"
            >
              <div className="w-full flex-col flex flex-1 mt-6 bg-white px-12 pt-6 rounded-t">
                {validationErrors.length > 0 && (
                  <div className="flex flex-col">
                    {validationErrors.map((error, index) => (
                      <span key={`validationErrors-${index}`}>{t(error)}</span>
                    ))}
                  </div>
                )}
                <div className="flex justify-between">
                  <span className="font-default text-xl text-yaleblue-800 font-semibold">
                    {t('creating_new_requote')}
                  </span>
                  <CloseIcon
                    className="h-3 w-3 cursor-pointer"
                    onClick={handleBackToPanel}
                  />
                </div>
                <div
                  className={`flex flex-col ${
                    inputsWithErrors.includes('newItems')
                      ? ' rounded-lg border border-red-salsa-500 '
                      : ''
                  }`}
                >
                  <div className="flex justify-between items-center mt-12">
                    <div className="flex items-center">
                      <span className="font-default font-semibold text-lg text-yaleblue-800">
                        1. {t('include_target')}
                      </span>
                      {inputsWithErrors.includes('newItems') && (
                        <div className="pl-2">
                          <AlertIcon />
                        </div>
                      )}
                    </div>
                    <div className="flex items-center">
                      <span className="font-default font-semibold text-sm text-yaleblue-900">
                        {`${items.length} ${t('items_selecteds')}`}
                      </span>
                      {requestIsOpen ? (
                        <ChavronDownIcon
                          className="cursor-pointer ml-2"
                          onClick={() => setRequestIsOpen(!requestIsOpen)}
                        />
                      ) : (
                        <ChavronUpIcon
                          className="cursor-pointer ml-2"
                          onClick={() => setRequestIsOpen(!requestIsOpen)}
                        />
                      )}
                    </div>
                  </div>
                  {requestIsOpen && (
                    <>
                      <div className="flex justify-between px-4 mt-4">
                        {fields.map((field, index) => (
                          <div
                            key={index}
                            className={`flex items-center ${
                              field.label === 'lead_time' ? 'ColumnWidth' : ''
                            } ${field.size || 'w-1/12'}`}
                          >
                            <div className="w-4 h-4 items-center flex">
                              {sortField.name === field.label ? (
                                sortField.value === 'desc' ? (
                                  <SortDescIcon
                                    className="cursor-pointer w-3 h-2.5"
                                    onClick={() => toggleSort(field.label)}
                                  />
                                ) : (
                                  <SortAscIcon
                                    className="cursor-pointer w-3 h-2.5"
                                    onClick={() => toggleSort(field.label)}
                                  />
                                )
                              ) : (
                                <SortDescIcon
                                  className="cursor-pointer w-3 h-2.5"
                                  onClick={() => toggleSort(field.label)}
                                />
                              )}
                            </div>

                            <span
                              title={`${t(field.label)}`}
                              className="font-default select-none font-bold text-sm text-bluejeans-800 truncate"
                            >
                              {t(field.label)}
                            </span>
                          </div>
                        ))}
                        <div className="flex h-3 w-4" />
                      </div>
                      <div className="flex flex-col">
                        {items.map((item, index) => {
                          const ipr = item.item_purchase_requisition;

                          const FeaturedComponent: React.FC = () => {
                            return (
                              <>
                                <span className="font-default font-normal text-sm text-yaleblue-900 w-1/12 break-words truncate">
                                  {removeZerosLeft(ipr.num_req)}
                                </span>
                                <span
                                  title={item.item_req}
                                  className="font-default font-normal text-sm text-yaleblue-900 w-1/12 break-words truncate"
                                >
                                  {removeZerosLeft(item.item_req)}
                                </span>
                                <span
                                  title={item.material.cod_material}
                                  className="font-default font-normal text-sm text-yaleblue-900 w-1/12 break-words truncate"
                                >
                                  {removeZerosLeft(item.material.cod_material)}
                                </span>
                                <div className="w-1/12 flex h-auto">
                                  <span
                                    title={item.long_description}
                                    className="font-default font-normal text-sm text-yaleblue-900 break-words truncate"
                                  >
                                    {item.long_description}
                                  </span>
                                </div>

                                <span className="font-default font-normal text-sm text-yaleblue-900 w-1/12 break-words truncate">
                                  {item.branch_region}
                                </span>
                                <span className="font-default font-normal text-sm text-yaleblue-900 w-1/12 break-words truncate">
                                  {item.warehouse || '-'}
                                </span>
                                <span className="font-default font-normal text-sm text-yaleblue-900 w-1/12 break-words truncate">
                                  {item.status_description || '-'}
                                </span>

                                <div className="flex items-center w-1/12 font-default font-normal text-sm text-yaleblue-900 break-words">
                                  <DeadlineDonut
                                    deadlineDate={item.sla_deadline}
                                    createdAt={
                                      item.dt_lib_rc ||
                                      item.item_purchase_requisition.created_on
                                    }
                                  />
                                </div>
                                <div className="flex w-2/12">
                                  <div className="w-4 h-4">
                                    <CalendarIcon />
                                  </div>

                                  <CustomDatePicker
                                    selected={new Date(item.dt_delivery)}
                                    onChange={(date: Date) =>
                                      handleChangeDate(date, item)
                                    }
                                    inputStyle="font-default ml-2.5 font-normal text-sm text-yaleblue-900 bg-transparent"
                                    minDate={new Date()}
                                  />
                                </div>
                                <div className="flex w-2/12">
                                  <div className="w-4 h-4">
                                    <DollarSignIcon className="min-w-full min-h-full" />
                                  </div>
                                  <CurrencyInput
                                    defaultValue={item.target}
                                    value={item.target}
                                    handleSetValue={(value: string) => {
                                      item.target = value;
                                    }}
                                    className="font-default ml-2.5 font-normal text-sm text-yaleblue-900 flex w-28 bg-transparent"
                                    placeholder="R$ 00,00"
                                  />
                                </div>
                                <div className="flex items-center ColumnWidth w-1/12">
                                  <div className="w-4 h-4">
                                    <WatchIcon />
                                  </div>
                                  <Input
                                    name="leadTime"
                                    type="number"
                                    onChange={event => {
                                      item.lead_time = event.target.value;
                                    }}
                                    value={item.lead_time}
                                    defaultValue={item.lead_time}
                                    className="font-default ml-2.5 font-normal text-sm text-yaleblue-900 bg-transparent w-12"
                                    placeholder="0"
                                    withoutContainer={true}
                                  />
                                </div>
                              </>
                            );
                          };
                          const ContentComponent: React.FC = () => {
                            const contentItems = [
                              {
                                label: 'status',
                                value: item.status,
                              },
                              {
                                label: 'rc_creation_date',
                                value: format(new Date(ipr.created_on), 'P', {
                                  locale: ptBR,
                                }),
                              },
                              {
                                label: 'long_description',
                                value: item.long_description,
                              },
                              {
                                label: 'buyer_group',
                                value: item.buyer_group?.name || '-',
                              },
                              {
                                label: 'quantity',
                                value: `${item.quantity}`,
                              },
                            ];

                            const lastPurchaseInfo = [
                              {
                                label: 'last_purchase_number',
                                value: item.last_purchase_order_number || '-',
                              },
                              {
                                label: 'date',
                                value: item.last_purchase_date
                                  ? format(
                                      new Date(item.last_purchase_date),
                                      'P',
                                      {
                                        locale: ptBR,
                                      },
                                    )
                                  : '-',
                              },
                              {
                                label: 'nickname',
                                value:
                                  item.last_purchase_supplier?.nickname || '-',
                              },
                              {
                                label: 'unit_price',
                                value:
                                  formatValue(item.last_purchase_price) || '-',
                              },
                              {
                                label: 'item',
                                value:
                                  removeZerosLeft(item.last_purchase_item) ||
                                  '-',
                              },
                              {
                                label: 'supplier',
                                value:
                                  removeZerosLeft(
                                    item.last_purchase_supplier?.code,
                                  ) || '-',
                              },
                              {
                                label: 'social_name',
                                value:
                                  item.last_purchase_supplier?.social_name ||
                                  '-',
                              },
                              {
                                label: 'POR',
                                value: item.last_purchase_vl_priceunit || '-',
                              },
                            ];

                            return (
                              <>
                                <div className="flex">
                                  <div className="flex flex-wrap mt-12 w-1/2 content-start">
                                    {contentItems.map((item, key) => (
                                      <ContentContainer
                                        className="flex justify-center h-24 pb-12"
                                        key={key}
                                      >
                                        <div className="flex flex-col px-4">
                                          <span className="font-default font-normal text-xs text-yaleblue-1000">
                                            {t(item.label)}
                                          </span>
                                          <div className="font-default font-normal text-sm text-yaleblue-1000 mt-2.5">
                                            <span className="font-default font-normal text-xs text-yaleblue-1000 break-words">
                                              {t(item.value)}
                                            </span>
                                          </div>
                                        </div>
                                      </ContentContainer>
                                    ))}
                                  </div>
                                  <div className="flex flex-col w-1/2">
                                    <div className="flex">
                                      <span className="font-default font-semibold text-sm text-yaleblue-1000">
                                        {t('last_purchase')}
                                      </span>
                                    </div>
                                    <div className="flex flex-wrap">
                                      {lastPurchaseInfo.map((item, index) => (
                                        <div
                                          className="flex justify-start items-center w-40 h-24 mr-4"
                                          key={index}
                                        >
                                          <div className="flex flex-col">
                                            <span className="font-default font-normal text-xs text-yaleblue-1000">
                                              {t(item.label)}
                                            </span>
                                            <div className="mt-2 flex justify-center">
                                              <span className="font-default font-normal text-xs text-yaleblue-1000">
                                                {t(item.value)}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="flex py-4">
                                      <div className="flex flex-col w-full">
                                        <span className="font-default font-bold text-xs text-basic-gray-500">
                                          {`${t('add_observation_to_quote')}:`}
                                        </span>
                                        <div className="flex w-full items-end justify-between mt-2">
                                          <Input
                                            name="observation"
                                            placeholder={t('observation')}
                                            className="w-2/3"
                                            onChange={event => {
                                              handleIncludeObservation(
                                                event,
                                                item,
                                              );
                                            }}
                                            value={item.observation || ''}
                                            autoComplete="off"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          };
                          return (
                            <ListItem
                              key={index}
                              FeaturedComponent={FeaturedComponent}
                              ContentComponent={ContentComponent}
                              className="mt-2 pb-4"
                              id={index}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex flex-col mt-10">
                  <div className="flex items-center">
                    <span className="font-default font-semibold text-lg text-yaleblue-800">
                      2. {t('suppliers')}
                    </span>
                    {inputsWithErrors.includes('newSuppliers') && (
                      <div className="pl-2">
                        <AlertIcon />
                      </div>
                    )}
                  </div>

                  <div className="flex w-full mt-4">
                    <MultiInput
                      register={register}
                      icon={AddIcon}
                      name={'suppliers'}
                      options={suppliersOptions}
                      className="w-2/5 mt-4"
                      placeholder={'Buscar por nome, código ou CNPJ'}
                      autoComplete="off"
                      handleSelectOption={handleSelectSupplier}
                      type={'select'}
                      url={
                        'suppliers/list_to_filter/?status=Liberado&supplier='
                      }
                      loadOptions={handleLoadedSuppliers}
                      selectedOptions={selectedSuppliers}
                      key={selectedSuppliers.length}
                      hasError={inputsWithErrors.includes('newSuppliers')}
                    />
                    <div className="flex ml-4">
                      <AppliedFilters
                        appliedFilters={suggestions.slice(0, 6).map(sug => {
                          return sug.code + ': ' + sug.social_name;
                        })}
                        className={
                          'text-bluejeans-1000 font-semibold cursor-pointer'
                        }
                        toogleAppliedFilter={addItem}
                        selectedItems={selectedSuppliers}
                        setSelectedItems={setSelectedSuppliers}
                        cardContent={supplierCardContent}
                        setCardContent={setSupplierCardContent}
                        handleCard={handleSupplierCard}
                        setSuggestions={setSuggestions}
                        suggestions={suggestions}
                      />
                      {suggestions.length > 0 && (
                        <span className="text-basic-gray-400 text-sm pr-4 pt-4">
                          {t('suggested_suppliers')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex mt-4 flex-wrap">
                    {supplierCardContent.length > 0 &&
                      supplierCardContent.map((item, key) => (
                        <SupplierCard
                          key={`sup-content-${key}`}
                          cardContent={item}
                          handleAction={handleUnselectSupplier}
                        />
                      ))}
                  </div>
                </div>
                <div className="flex flex-col mt-10">
                  <div className="flex items-center">
                    <span className="font-default font-semibold text-lg text-yaleblue-800">
                      3. {t('limit_date')}
                    </span>
                    {inputsWithErrors.includes('dtDelivery') && (
                      <div className="pl-2">
                        <AlertIcon />
                      </div>
                    )}
                  </div>
                  <div className="flex mt-4">
                    <DatePicker
                      selected={dtDelivery}
                      onChange={(date: Date) => setDtDelivery(date)}
                      minDate={new Date()}
                      placeholder="dd/mm/aaaa"
                      hasError={inputsWithErrors.includes('dtDelivery')}
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-10">
                  <div className="flex items-center">
                    <span className="font-default font-semibold text-lg text-yaleblue-800">
                      4. {t('rfq_closing_time')}
                    </span>
                    {inputsWithErrors.includes('dtDelivery') && (
                      <div className="pl-2">
                        <AlertIcon />
                      </div>
                    )}
                  </div>
                  <div className="flex mt-4">
                    <TimePicker
                      selected={dtDelivery}
                      onChange={(date: Date) => setDtDelivery(date)}
                      hasError={inputsWithErrors.includes('dtDelivery')}
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-10">
                  <div className="flex items-center">
                    <span className="font-default font-semibold text-lg text-yaleblue-800">
                      5. {t('payment_method')}
                    </span>
                    {inputsWithErrors.includes('paymentMethod') && (
                      <div className="pl-2">
                        <AlertIcon />
                      </div>
                    )}
                  </div>
                  <div className="flex mt-4 items-center">
                    <div className="w-2/12">
                      <CustomDropDown
                        toggleAction={handlePaymentMethod}
                        options={paymentOptions}
                        title={t('payment_method')}
                        url={'payment_condition/list_to_filter/?description='}
                        handleOptions={handleLoadedPaymentMethod}
                        selectedItem={paymentMethod}
                        hasError={inputsWithErrors.includes('paymentMethod')}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-10">
                  <div className="flex">
                    <span className="font-default font-semibold text-lg text-yaleblue-800">
                      6. {t('supplier_note')}
                    </span>
                  </div>
                  <div className="flex mt-4 items-center">
                    <div className="w-1/3">
                      <Input
                        className="w-full rounded-lg"
                        placeholder={t('observation')}
                        name="supplierNote"
                        onChange={handleSupplierObservation}
                        value={supplierObservation}
                        autoComplete="off"
                      />
                    </div>
                    <button
                      type="button"
                      className={`h-8 px-2 w-40 flex ml-3 items-center justify-between bg-white text-sm text-bluejeans-800 font-normal rounded-lg border-2`}
                    >
                      {t('attach_file')}
                      <PaperIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <div className="flex justify-end mt-10 h-full items-end pb-4">
                  {createVendorList ? (
                    <CheckBoxChecked
                      className="mb-3 mr-1.5 cursor-pointer"
                      onClick={handleVendorList}
                    />
                  ) : (
                    <CheckBoxUnchecked
                      className="mb-3 mr-1.5 cursor-pointer"
                      onClick={handleVendorList}
                    />
                  )}
                  <span className="mb-2.5 mr-3">{t('create_vendor_list')}</span>
                  <Button type="submit">{t('create_new_requote')}</Button>
                </div>
              </div>
            </form>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              ariaHideApp={false}
              className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
            >
              <div className="flex flex-col justify-center items-center">
                <ConfirmationIcon />
                <div className="my-4">
                  <span className="font-default font-normal text-sm text-yaleblue-1000">
                    {`${t('requote_created')} `}
                  </span>
                </div>

                <Button onClick={() => closeModal()}>
                  {t('confirmation_modal')}
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={errorModalIsOpen}
              onRequestClose={closeErrorModal}
              ariaHideApp={false}
              className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
            >
              <div className="flex flex-col justify-center items-center">
                <div className="w-full flex justify-end cursor-pointer">
                  <CloseIcon onClick={() => closeErrorModal()} />
                </div>
                <DeleteIcon />
                <div className="my-4 mt-10 mb-7">
                  <div className="font-default font-normal text-sm text-yaleblue-1000">
                    {t('form_with_empty_fields')}
                  </div>
                </div>
                <div className="flex items-center ">
                  <Button
                    className="h-12 px-8 text-base"
                    onClick={() => closeErrorModal()}
                  >
                    {t('confirmation_modal')}
                  </Button>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Requote;
