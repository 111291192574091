import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import Modal from 'react-modal';
import Navbar from '../../../components/Navbar/navbar';
import { Container, Content } from './NewVendorList.style';
import Button from '../../../components/Button/Button';
import MultiInput from '../../../components/MultiInput/MultiInput';
import ListSelectItem from '../../../components/ListSelectItem/ListSelectItem';
import SupplierCard from '../../../components/SupplierCard/SupplierCard';
import { ReactComponent as IconBreadcrumb } from '../../../assets/icon-breadcrumb.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon-blue.svg';
import { ReactComponent as AddIcon } from '../../../assets/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as AlertIcon } from '../../../assets/alert-icon.svg';
import Loading from '../../../components/Loading/Loading';
import { getEmptyRequiredInputs } from '../../../utils/getEmptyRequiredInputs';
import DowloadIcon from '../../../assets/download-icon.svg';
import { handleFieldsValidate } from '../../../utils/fieldsValidation';
import ReactLoading from 'react-loading';

interface MultiInputOptions {
  id: number;
  value: string;
}
interface ObjectProps {
  id: number;
  description: string;
}

interface BuyerGroupProps extends ObjectProps {
  name: string;
  selected: boolean;
}

interface WarehouseProps extends ObjectProps {
  code: string;
  selected: boolean;
}

interface MaterialProps extends ObjectProps {
  cod_material: string;
}

interface SuppliersProps extends ObjectProps {
  id: number;
  code: string;
  social_name: string;
  nickname: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  PMP: number;
  saving_percent: number;
  deadline: string;
  selected: boolean;
  [key: string]: number | string | boolean;
}

interface cardContent {
  id: number;
  nickname: string;
  code: string;
  social_name: string;
  document_number: string;
  uf: string;
  country: string;
  score_serasa: number;
  payment_deadline: number;
  fqi: number;
  saving_percent: number;
}

const NewVendorList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [buyerGroups, setBuyerGroups] = useState<BuyerGroupProps[]>([]);
  const [suppliers, setSuppliers] = useState<SuppliersProps[]>([]);
  const [buyerGroupList, setBuyerGroupList] = useState<BuyerGroupProps[]>([]);
  const [loading, setLoading] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState<
    MultiInputOptions[]
  >([]);
  const [categoriesOptions, setCategoriesOptions] = useState<
    MultiInputOptions[]
  >([]);

  const [selectedMaterials, setSelectedMaterials] = useState<
    MultiInputOptions[]
  >([]);
  const [materialsOptions, setMaterialsOptions] = useState<MultiInputOptions[]>(
    [],
  );
  const [warehousesOptions, setWarehousesOptions] = useState<
    MultiInputOptions[]
  >([]);

  const [selectedGroupOptions, setSelectedGroupOptions] = useState<
    MultiInputOptions[]
  >([]);
  const [groupOptions, setGroupOptions] = useState<MultiInputOptions[]>([]);

  const [selectedBuyerGroups, setSelectedBuyerGroups] = useState<
    MultiInputOptions[]
  >([]);
  const [buyerGroupOptions, setBuyerGroupOptions] = useState<
    MultiInputOptions[]
  >([]);

  const [selectedRegions, setSelectedRegions] = useState<MultiInputOptions[]>(
    [],
  );
  const [regionsOptions, setRegionsOptions] = useState<MultiInputOptions[]>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    MultiInputOptions[]
  >([]);
  const [suppliersOptions, setSuppliersOptions] = useState<MultiInputOptions[]>(
    [],
  );
  const [supplierCardContent, setSupplierCardContent] = useState<cardContent[]>(
    [],
  );

  const [selectedWarehouses, setSelectedWarehouses] = useState<
    MultiInputOptions[]
  >([]);
  const [inputsWithErrors, setInputsWithErrors] = useState<Array<string>>([]);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [fileName, setFileName] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState('');
  const [typedOptionBuyerGroup, setTypedOptionBuyerGroup] = useState('');
  const [typedOptionMaterial, setTypedOptionMaterial] = useState('');
  const [typedOptionWarehouse, setTypedOptionWarehouse] = useState('');
  const [typedOptionGroup, setTypedOptionGroup] = useState('');
  const [typedOptionCenter, setTypedOptionCenter] = useState('');
  const [typedOptionSupplier, setTypedOptionSupplier] = useState('');

  const { register, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState('');

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  const handleSupplierCard = (supplier: SuppliersProps): cardContent => {
    const cardContent: cardContent = {
      id: supplier.id,
      nickname: supplier.nickname || '-',
      code: supplier.code || '-',
      social_name: supplier.social_name || '-',
      document_number: supplier.document_number || '-',
      uf: supplier.state || '-',
      country: supplier.country || '-',
      score_serasa: supplier.score_serasa || 0,
      payment_deadline: supplier.PMP || 0,
      fqi: supplier.quality_percent || 0,
      saving_percent: supplier.saving_percent || 0,
    };

    return cardContent;
  };

  const handleSelectBuyerGroup = (option: string) => {
    const index = buyerGroups.findIndex(bg => bg.name === option);
    buyerGroupList.push(buyerGroups[index]);
    selectedBuyerGroups.push({
      id: buyerGroups[index].id,
      value: buyerGroups[index].name,
    });
    setSelectedBuyerGroups([...selectedBuyerGroups]);
    setBuyerGroupList([...buyerGroupList]);
  };

  const onSubmit = () => {
    const newCategories: Object[] = [];
    const newMaterials: Object[] = [];
    const newRegions: Object[] = [];
    const newGrps: Object[] = [];
    const newMerchandiseGroups: Object[] = [];
    const newSuppliers: Object[] = [];
    const newWarehouses: Object[] = [];

    if (selectedCategories) {
      selectedCategories.forEach(category => {
        newCategories.push({ id: category.id });
      });
    }
    if (selectedMaterials) {
      selectedMaterials.forEach(material => {
        newMaterials.push({ id: material.id });
      });
    }

    if (selectedRegions) {
      selectedRegions.forEach(region => {
        newRegions.push({ id: region.id });
      });
    }

    if (selectedSuppliers) {
      selectedSuppliers.forEach(supplier => {
        newSuppliers.push({ id: supplier.id });
      });
    }

    if (selectedBuyerGroups) {
      selectedBuyerGroups.forEach(buyerGroup => {
        newGrps.push({ id: buyerGroup.id });
      });
    }

    if (selectedGroupOptions) {
      selectedGroupOptions.forEach(group => {
        newMerchandiseGroups.push({ id: group.id });
      });
    }
    if (selectedWarehouses) {
      selectedWarehouses.forEach(group => {
        newWarehouses.push({ id: group.id });
      });
    }
    const payload = {
      active: true,
      regions: newRegions,
      categories: newCategories,
      suppliers: newSuppliers,
      buyer_groups: newGrps,
      materials: newMaterials,
      merchandise_groups: newMerchandiseGroups,
      warehouses: newWarehouses,
    };

    const emptyRequiredInputs = getEmptyRequiredInputs(
      [
        {
          fieldObject: selectedSuppliers,
          fieldName: 'selectedSuppliers',
        },
        {
          fieldObject: selectedGroupOptions,
          fieldName: 'selectedGroupOptions',
        },
        {
          fieldObject: selectedCategories,
          fieldName: 'selectedCategories',
        },
        {
          fieldObject: selectedRegions,
          fieldName: 'selectedRegions',
        },
        {
          fieldObject: selectedMaterials,
          fieldName: 'selectedMaterials',
        },
        {
          fieldObject: selectedWarehouses,
          fieldName: 'selectedWarehouses',
        },
        {
          fieldObject: selectedBuyerGroups,
          fieldName: 'selectedBuyerGroups',
        },
      ],
      true,
    );

    setInputsWithErrors(emptyRequiredInputs);

    async function createVendorList(): Promise<void> {
      if (emptyRequiredInputs.length > 0) {
        if (emptyRequiredInputs.includes('selectedSuppliers'))
          setErrorMessage(`${t('form_with_empty_fields')}`);
        else setErrorMessage(`${t('fill_one_field')}`);
        return setErrorModalIsOpen(true);
      }

      try {
        showLoader(true);
        const response = await api.post(
          '/vendor_lists/add_with_relations/',
          payload,
        );
        if (response.status === 201) {
          history.push('/suppliers', { active_tab: 'vendor_list' });
        }
      } catch (error) {
        console.log(error);
      } finally {
        showLoader(false);
      }
    }

    createVendorList();
  };

  const handleSelectSupplier = (option: MultiInputOptions) => {
    if (!selectedSuppliers.find(sup => sup.id === option.id)) {
      const supIndex = suppliers.findIndex(
        supplier => supplier.id === option.id,
      );
      const supplier = suppliers[supIndex];
      supplier.selected = true;

      setSupplierCardContent([
        ...supplierCardContent,
        handleSupplierCard(supplier),
      ]);
    }
  };

  function handleUnselectSupplier(content: cardContent) {
    const supplierIndex = selectedSuppliers.findIndex(
      sup => sup.id === content.id,
    );
    const newSupplierCardContent = supplierCardContent.slice();

    const contentIndex = newSupplierCardContent.findIndex(
      cont => cont.id === content.id,
    );

    newSupplierCardContent.splice(contentIndex, 1);
    setSupplierCardContent(newSupplierCardContent);
    const selecteds = selectedSuppliers.filter(
      sup => sup.id !== selectedSuppliers[supplierIndex].id,
    );
    setSelectedSuppliers([...selecteds]);
  }

  const removeBuyerGroup = (id: number) => {
    setSelectedBuyerGroups([
      ...selectedBuyerGroups.filter(function (bg) {
        return bg.id !== id;
      }),
    ]);
    setBuyerGroupList([
      ...buyerGroupList.filter(function (bg) {
        return bg.id !== id;
      }),
    ]);
  };

  const handleLoadedCategories = (items: never[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];
    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { name: string; erp_id: string; id: number }) => {
      const newCategory = {
        id: item.id,
        value: String(item.erp_id).concat(' - ', item.name),
      };
      options.push(newCategory);
    });
    setCategoriesOptions(options);
  };

  const handleLoadedBgGroups = (items: never[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];
    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }

    items.forEach((item: { name: string; id: number }) => {
      const newBuyerGroup = {
        id: item.id,
        value: item.name,
      };
      options.push(newBuyerGroup);
    });
    setBuyerGroupOptions(options);
    setBuyerGroups([...items]);
  };

  const handleLoadedMaterials = (
    items: MaterialProps[],
    loadedOptions: never[],
  ) => {
    const options: MultiInputOptions[] = materialsOptions;

    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { cod_material: string; id: number }) => {
      const newBuyerGroup = {
        id: item.id,
        value: item.cod_material,
      };
      options.push(newBuyerGroup);
    });
    setMaterialsOptions([...options]);
  };

  const handleLoadedMerchandiseGp = (
    items: never[],
    loadedOptions: never[],
  ) => {
    const options: MultiInputOptions[] = [];

    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { name: string; id: number }) => {
      const newMerchandise = {
        id: item.id,
        value: item.name,
      };
      options.push(newMerchandise);
    });
    setGroupOptions([...options]);
  };

  const handleLoadedWarehouse = (items: never[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];

    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { code: string; id: number }) => {
      const newWarehouse = {
        id: item.id,
        value: item.code,
      };
      options.push(newWarehouse);
    });
    setWarehousesOptions([...options]);
  };

  const handleLoadedRegions = (items: never[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];

    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { name: string; id: number; code: string }) => {
      const newRegion = {
        id: item.id,
        value: item.name ?? item.code,
      };
      options.push(newRegion);
    });
    setRegionsOptions([...options]);
  };

  const handleLoadedSuppliers = (items: never[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];

    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { social_name: string; id: number; code: string }) => {
      const newSupplier = {
        id: item.id,
        value: item.code + ': ' + item.social_name,
      };
      options.push(newSupplier);
    });

    setSuppliersOptions([...options]);
    setSuppliers([...items]);
  };

  const handleSelectedOptions = (
    selectedOptions: MultiInputOptions[],
    input: string,
  ) => {
    switch (input) {
      case 'categories':
        setSelectedCategories([...selectedOptions]);
        break;
      case 'materials':
        setSelectedMaterials([...selectedOptions]);
        break;
      case 'merchandise_group':
        setSelectedGroupOptions([...selectedOptions]);
        break;
      case 'regions':
        setSelectedRegions([...selectedOptions]);
        break;
      case 'suppliers':
        setSelectedSuppliers([...selectedOptions]);
        selectedOptions.forEach(opt => {
          handleSelectSupplier(opt);
        });
        break;
      case 'warehouses':
        setSelectedWarehouses([...selectedOptions]);
        break;
      default:
        break;
    }
  };

  function closeErrorModal() {
    setErrorModalIsOpen(false);
  }

  async function uploadFile(evt: ChangeEvent<HTMLInputElement>): Promise<void> {
    const input = evt.target;
    if (!input.files?.length) return;
    try {
      const file = input.files;
      const data = new FormData();
      const materialOptions: MultiInputOptions[] = [];
      data.append('material_file', file[0]);
      await api.post('/materials/import_csv/', data).then(response => {
        setFileName(file[0].name);
        response.data.materials.forEach(
          (material: { id: number; cod_material: string }) => {
            materialOptions.push({
              id: material.id,
              value: material.cod_material,
            });
          },
        );
        handleSelectedOptions(materialOptions, 'materials');
      });
    } catch (error) {
      setErrorMessage(`{t('import_file_with_errors')}`);
      setErrorModalIsOpen(true);
    }
  }

  return (
    <Container className="flex-1 flex-col bg-primary">
      {loading && <Loading />}
      <Navbar pageTitle={''} selectedMenu="suppliers" />
      <div className="flex items-center px-12 xl:px-20">
        <span className="font-default font-normal text-white text-sm">
          {t('vendor_list')}
        </span>
        <IconBreadcrumb className="h-2 w-2 mx-4" />
        <span className="font-default font-bold text-white text-sm">
          {t('creating_vendor_list')}
        </span>
      </div>
      <div className="flex overflow-y-auto w-full px-12 xl:px-20 justify-center">
        <Content
          onSubmit={handleSubmit(onSubmit)}
          className="w-full mt-6 bg-white px-12 py-10 rounded-t-lg overflow-y-auto flex flex-col"
        >
          <div className="flex justify-between">
            <span className="font-default text-2xl text-yaleblue-800 font-semibold">
              {t('create_vendor_list')}
            </span>
            <Link to="/suppliers">
              <CloseIcon className="h-3 w-3" />
            </Link>
          </div>
          <div className="flex flex-col mt-10">
            <span className="font-default font-semibold text-lg text-yaleblue-800">
              1. {t('category')}
            </span>
            <div className="flex w-full items-baseline">
              <MultiInput
                type={'select'}
                register={register}
                icon={AddIcon}
                name={'categories'}
                options={categoriesOptions}
                className="w-2/5 mt-4"
                placeholder={t('select_category')}
                autoComplete="off"
                showSelectedItems={true}
                url={'categories/list_to_filter/?name='}
                loadOptions={handleLoadedCategories}
                handleSelectedOptions={handleSelectedOptions}
                selectedOptions={selectedCategories}
              />
            </div>
          </div>

          <div className="flex flex-col mt-10">
            <span className="font-default font-semibold text-lg text-yaleblue-800">
              2. {t('buyer_group')}
            </span>
            <div className="flex w-full mt-4 items-baseline">
              <MultiInput
                type={'select'}
                register={register}
                icon={AddIcon}
                name={'buyer_groups'}
                options={buyerGroupOptions}
                className="w-2/5 mt-4"
                placeholder={t('bind_buyer_group')}
                autoComplete="off"
                handleSelectOption={handleSelectBuyerGroup}
                showSelectedItems={false}
                url={'buyer_groups/list_to_filter/?name='}
                loadOptions={handleLoadedBgGroups}
                selectedOptions={selectedBuyerGroups}
                key={buyerGroupList.length}
                typedOption={typedOptionBuyerGroup}
                setTypedOption={setTypedOptionBuyerGroup}
              />
              <Button
                className="mt-4 ml-4"
                isLoading={isLoading === 'buyer_groups'}
                disabled={isLoading === 'buyer_groups'}
                onClick={() =>
                  handleFieldsValidate(
                    setIsLoading,
                    t,
                    typedOptionBuyerGroup,
                    'buyer_groups/list_to_filter/?name=',
                    setErrorMessage,
                    setErrorModalIsOpen,
                    selectedBuyerGroups,
                    setSelectedBuyerGroups,
                    setBuyerGroupList,
                    buyerGroupList,
                  ).finally(() => setTypedOptionBuyerGroup(''))
                }
              >
                {t('validate_groups')}
              </Button>
              {isLoading === 'buyer_groups' ? (
                <ReactLoading
                  className="ml-4"
                  type={'spin'}
                  color={'#3D6F9B'}
                  height={18}
                  width={24}
                />
              ) : null}
            </div>
            <div className="mt-6">
              {buyerGroupList.map((item, key) => (
                <ListSelectItem
                  labels={item}
                  key={key}
                  itemKey={item.id}
                  toogleAction={removeBuyerGroup}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col mt-10">
            <span className="font-default font-semibold text-lg text-yaleblue-800">
              3. {t('material_plural')}
            </span>
            <div className="flex w-full items-baseline">
              <MultiInput
                type={'select'}
                register={register}
                icon={AddIcon}
                name={'materials'}
                options={materialsOptions}
                className="w-2/5 mt-4"
                placeholder={t('bind_material')}
                autoComplete="off"
                showSelectedItems={true}
                url={'materials/list_to_filter/?cod_material='}
                loadOptions={handleLoadedMaterials}
                handleSelectedOptions={handleSelectedOptions}
                selectedOptions={selectedMaterials}
                typedOption={typedOptionMaterial}
                setTypedOption={setTypedOptionMaterial}
              />
              <Button
                className="mt-4 ml-4"
                isLoading={isLoading === 'materials'}
                disabled={isLoading === 'materials'}
                onClick={() =>
                  handleFieldsValidate(
                    setIsLoading,
                    t,
                    typedOptionMaterial,
                    'materials/list_to_filter/?name=',
                    setErrorMessage,
                    setErrorModalIsOpen,
                    selectedMaterials,
                    setSelectedMaterials,
                  ).finally(() => setTypedOptionMaterial(''))
                }
              >
                {t('validate_materials')}
              </Button>
              {isLoading === 'materials' ? (
                <ReactLoading
                  className="ml-4"
                  type={'spin'}
                  color={'#3D6F9B'}
                  height={18}
                  width={24}
                />
              ) : null}
              <div className="flex relative border-2 border-bluejeans-800 rounded mt-4 ml-4 h-8 px-2.5 items-center cursor-pointer">
                <input
                  type="file"
                  accept=".csv"
                  className="opacity-0 absolute"
                  onChange={e => uploadFile(e)}
                />
                <span className="text-bluejeans-800 text-sm mr-2">
                  {t('import_csv')}
                </span>
                <div className="flex justify-center h-5 cursor-pointer">
                  <img src={DowloadIcon} className="w-4 h-5" alt="" />
                </div>
              </div>
              <span className="text-bluejeans-800 text-sm ml-2">
                {fileName}
              </span>
            </div>
          </div>
          <div className="flex flex-col mt-10">
            <span className="font-default font-semibold text-lg text-yaleblue-800">
              4. {t('region')}
            </span>
            <div className="flex w-full mt-4 items-baseline">
              <MultiInput
                register={register}
                icon={AddIcon}
                options={regionsOptions}
                placeholder={t('select_region')}
                name={'regions'}
                className="w-2/5 mt-4"
                autoComplete="off"
                type={'select'}
                showSelectedItems={true}
                selectedOptions={selectedRegions}
                handleSelectedOptions={handleSelectedOptions}
                url={'centers/list_to_filter/?name='}
                loadOptions={handleLoadedRegions}
                typedOption={typedOptionCenter}
                setTypedOption={setTypedOptionCenter}
              />
              <Button
                className="mt-4 ml-4"
                isLoading={isLoading === 'centers'}
                disabled={isLoading === 'centers'}
                onClick={() =>
                  handleFieldsValidate(
                    setIsLoading,
                    t,
                    typedOptionCenter,
                    'centers/list_to_filter/?name=',
                    setErrorMessage,
                    setErrorModalIsOpen,
                    selectedRegions,
                    setSelectedRegions,
                  ).finally(() => setTypedOptionCenter(''))
                }
              >
                {t('validate_centers')}
              </Button>
              {isLoading === 'centers' ? (
                <ReactLoading
                  className="ml-4"
                  type={'spin'}
                  color={'#3D6F9B'}
                  height={18}
                  width={24}
                />
              ) : null}
            </div>
          </div>
          <div className="flex flex-col mt-10">
            <div className="flex items-center">
              <span className="font-default font-semibold text-lg text-yaleblue-800">
                5. {t('merchandise_group')}
              </span>
            </div>
            <div className="flex w-full items-baseline">
              <MultiInput
                type={'select'}
                register={register}
                icon={AddIcon}
                options={groupOptions}
                placeholder={t('select_merchandise_group')}
                name={'merchandise_group'}
                className="w-2/5 mt-4"
                autoComplete="off"
                showSelectedItems={true}
                url={'merchandise_groups/list_to_filter/?description='}
                loadOptions={handleLoadedMerchandiseGp}
                handleSelectedOptions={handleSelectedOptions}
                selectedOptions={selectedGroupOptions}
                typedOption={typedOptionGroup}
                setTypedOption={setTypedOptionGroup}
              />
              <Button
                className="mt-4 ml-4"
                isLoading={isLoading === 'merchandise_groups'}
                disabled={isLoading === 'merchandise_groups'}
                onClick={() =>
                  handleFieldsValidate(
                    setIsLoading,
                    t,
                    typedOptionGroup,
                    'merchandise_groups/list_to_filter/?name=',
                    setErrorMessage,
                    setErrorModalIsOpen,
                    selectedGroupOptions,
                    setSelectedGroupOptions,
                  ).finally(() => setTypedOptionGroup(''))
                }
              >
                {t('validate_merchandise')}
              </Button>
              {isLoading === 'merchandise_groups' ? (
                <ReactLoading
                  className="ml-4"
                  type={'spin'}
                  color={'#3D6F9B'}
                  height={18}
                  width={24}
                />
              ) : null}
            </div>
          </div>

          <div className="flex flex-col mt-10">
            <div className="flex items-center">
              <span className="font-default font-semibold text-lg text-yaleblue-800">
                6. {t('warehouse')}
              </span>
            </div>
            <div className="flex w-full items-baseline">
              <MultiInput
                type={'select'}
                register={register}
                icon={AddIcon}
                options={warehousesOptions}
                placeholder={t('select_warehouse')}
                name={'warehouses'}
                className="w-2/5 mt-4"
                autoComplete="off"
                showSelectedItems={true}
                url={'warehouses/list_to_filter/?code='}
                loadOptions={handleLoadedWarehouse}
                handleSelectedOptions={handleSelectedOptions}
                selectedOptions={selectedWarehouses}
                typedOption={typedOptionWarehouse}
                setTypedOption={setTypedOptionWarehouse}
              />
              <Button
                className="mt-4 ml-4"
                isLoading={isLoading === 'warehouses'}
                disabled={isLoading === 'warehouses'}
                onClick={() =>
                  handleFieldsValidate(
                    setIsLoading,
                    t,
                    typedOptionWarehouse,
                    'warehouses/list_to_filter/?code=',
                    setErrorMessage,
                    setErrorModalIsOpen,
                    selectedWarehouses,
                    setSelectedWarehouses,
                  ).finally(() => setTypedOptionWarehouse(''))
                }
              >
                {t('validate_warehouses')}
              </Button>
              {isLoading === 'warehouses' ? (
                <ReactLoading
                  className="ml-4"
                  type={'spin'}
                  color={'#3D6F9B'}
                  height={18}
                  width={24}
                />
              ) : null}
            </div>
          </div>
          <div className="flex flex-col mt-10">
            <div className="flex items-center">
              <span className="font-default font-semibold text-lg text-yaleblue-800">
                7. {t('suppliers')}
              </span>
              {inputsWithErrors.includes('selectedSuppliers') && (
                <div className="pl-2">
                  <AlertIcon />
                </div>
              )}
            </div>
            <div className="flex w-full items-baseline">
              <MultiInput
                type={'select'}
                register={register}
                icon={AddIcon}
                options={suppliersOptions}
                placeholder={t('select_suppliers')}
                name={'suppliers'}
                className="w-2/5 mt-4"
                autoComplete="off"
                url={'suppliers/list_to_filter/?supplier='}
                loadOptions={handleLoadedSuppliers}
                handleSelectedOptions={handleSelectedOptions}
                selectedOptions={selectedSuppliers}
                key={selectedSuppliers.length}
                hasError={inputsWithErrors.includes('selectedSuppliers')}
                typedOption={typedOptionSupplier}
                setTypedOption={setTypedOptionSupplier}
              />
              <Button
                className="mt-4 ml-4"
                isLoading={isLoading === 'suppliers'}
                disabled={isLoading === 'suppliers'}
                onClick={() =>
                  handleFieldsValidate(
                    setIsLoading,
                    t,
                    typedOptionSupplier,
                    'suppliers/list_to_filter/?supplier=',
                    setErrorMessage,
                    setErrorModalIsOpen,
                    selectedSuppliers,
                    setSelectedSuppliers,
                    setSupplierCardContent,
                    supplierCardContent,
                    handleSupplierCard,
                  ).finally(() => setTypedOptionSupplier(''))
                }
              >
                {t('validate_suppliers')}
              </Button>
              {isLoading === 'suppliers' ? (
                <ReactLoading
                  className="ml-4"
                  type={'spin'}
                  color={'#3D6F9B'}
                  height={18}
                  width={24}
                />
              ) : null}
            </div>
            <div className="flex mt-4">
              {supplierCardContent.length > 0 &&
                supplierCardContent.map((item, key) => (
                  <SupplierCard
                    key={`sup-content-${key}`}
                    cardContent={item}
                    handleAction={handleUnselectSupplier}
                  />
                ))}
            </div>
          </div>
          <div className="flex justify-end items-center mt-10">
            <Link
              className="font-default font-bold underline text-bluejeans-800 text-sm mr-16"
              to="/suppliers"
            >
              {t('cancel')}
            </Link>
            <Button type="submit" className="h-12">
              {t('confirm_vendor_list')}
            </Button>
          </div>
        </Content>
      </div>
      <Modal
        isOpen={errorModalIsOpen}
        onRequestClose={closeErrorModal}
        ariaHideApp={false}
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-full flex justify-end cursor-pointer">
            <CloseIcon onClick={closeErrorModal} />
          </div>
          <DeleteIcon />
          <div className="my-4 mt-10 mb-7">
            <div className="font-default font-normal text-sm text-yaleblue-1000">
              {errorMessage}
            </div>
          </div>
          <div className="flex items-center">
            <Button className="h-12 px-8 text-base" onClick={closeErrorModal}>
              {t('confirmation_modal')}
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default NewVendorList;
