/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Tooltip from 'rc-tooltip';
import moment from 'moment';
import {
  Container,
  Content,
  ContentContainer,
  ErrorBox,
  BoxAlert,
} from './NewQuotation.style';
import Navbar from '../../../components/Navbar/navbar';
import SupplierCard from '../../../components/SupplierCard/SupplierCard';
import Input from '../../../components/Input/Input';
import DatePicker from '../../../components/DatePicker/DatePicker';
import TimePicker from '../../../components/TimePicker/TimePicker';
import Button from '../../../components/Button/Button';
import ListItem from '../../../components/ListItem/ListItem';
import DeadlineDonut from '../../../components/DeadlineDonut/DeadlineDonut';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import { ReactComponent as IconBreadcrumb } from '../../../assets/icon-breadcrumb.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon-blue.svg';
import { ReactComponent as AddIcon } from '../../../assets/add-icon.svg';
import MultiInput from '../../../components/MultiInput/MultiInput';
import { ReactComponent as PaperIcon } from '../../../assets/paper-clip-icon.svg';
import { ReactComponent as ChavronUpIcon } from '../../../assets/icon-chavron-down.svg';
import { ReactComponent as ChavronDownIcon } from '../../../assets/icon-chavron-up.svg';
import { ReactComponent as SortDescIcon } from '../../../assets/icon-sort-desc.svg';
import { ReactComponent as SortAscIcon } from '../../../assets/icon-sort-asc.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { ReactComponent as ConfirmationIcon } from '../../../assets/confirmation-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as AlertIcon } from '../../../assets/alert-icon.svg';
import { ReactComponent as CheckBoxChecked } from '../../../assets/checkbox-checked-icon.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../../assets/checkbox-unchecked-icon.svg';
import {
  formatContentItems,
  formatLineItems,
} from '../../../helpers/NewQuotationHelpers/FormatItem';
import api from '../../../services/api';
import Modal from 'react-modal';
import './NewQuotation.css';
import Loading from '../../../components/Loading/Loading';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';
import { format } from 'date-fns';
import { getEmptyRequiredInputs } from '../../../utils/getEmptyRequiredInputs';
import removeZerosLeft from '../../../utils/removeZerosLeft';
import formatValue from '../../../utils/formatValue';
import { AxiosError } from 'axios';
import CustomDropDown from '../../../components/CustomDropdown/CustomDropdown';
import AppliedFilters from '../../../components/AppliedFilters/AppliedFilter';
import { loadSuggestions, addItem } from '../utils';

interface Supplier {
  id: number;
  code: string;
  social_name: string;
  nickname: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  PMP: number;
  saving_percent: number;
  deadline: string;
  selected: boolean;
  [key: string]: number | string | boolean;
}

interface cardContent {
  id: number;
  nickname: string;
  code: string;
  social_name: string;
  document_number: string;
  uf: string;
  country: string;
  score_serasa: number;
  payment_deadline: number;
  fqi: number;
  saving_percent: number;
}
interface SortFieldProps {
  name: string;
  value: string;
}
interface ContentItems {
  [index: string]: string;
}
interface LineItems {
  observation: string;
  urgency: string;
  dt_lib_rc: string;
  dt_delivery: Date;
  sla_deadline_raw: string;
  vendor_list: string;
  [index: string]: number | string | Date | Object;
}
interface MultiInputOptions {
  id: number;
  value: string;
}
interface ItemsOptionProps {
  id: number;
  label: string;
}

const NewQuotation: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const location = useLocation<{
    selectedItems: never[];
  }>();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [suggestions, setSuggestions] = useState<Supplier[]>([]);
  const [suppliersOptions, setSuppliersOptions] = useState<MultiInputOptions[]>(
    [],
  );
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    MultiInputOptions[]
  >([]);
  const [supplierCardContent, setSupplierCardContent] = useState<cardContent[]>(
    [],
  );

  const [requestIsOpen, setRequestIsOpen] = useState(true);
  const [supplierObservation, setSupplierObservation] = useState('');
  const [sortField, setSortField] = useState<SortFieldProps>({
    name: '',
    value: '',
  });
  const [dtDelivery, setDtDelivery] = useState<Date | null>(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showModalDeadlineError, setShowModalDeadlineError] = useState(false);
  const [deadlineErrorIds, setDeadlineErrorIds] = useState<
    string[] | number[] | Object[] | Date[]
  >([]);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [lineItems, setLineItems] = useState<LineItems[]>([]);
  const [contentItems, setContentItems] = useState<Array<Object>>([]);
  const [loading, setLoading] = useState(true);
  const [responseError, setResponseError] = useState<AxiosError | null>();
  const [inputsWithErrors, setInputsWithErrors] = useState<Array<string>>([]);
  const [buyerGroups, setBuyerGroups] = useState<ItemsOptionProps[]>([]);
  const [buyerGroup, setBuyerGroup] = useState<{ id: number; name: string }>();
  const [createVendorList, setCreateVendorList] = useState(false);
  const fields = [
    { label: 'requisition', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'item', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'material', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'center', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'warehouse', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'description', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'urgency', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'sla_deadline', size: 'md:w-1/12 lg:w-1/12' },
    { label: 'remittance_dt', size: 'md:w-1/12 lg:w-1/12' },
  ];

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    history.push('/purchase-panel');
  }

  function closeErrorModal() {
    setErrorModalIsOpen(false);
  }
  const handleVendorList = () => {
    setCreateVendorList(!createVendorList);
  };

  const handleSupplierCard = (supplier: Supplier): cardContent => {
    return {
      id: supplier.id,
      nickname: supplier.nickname || '-',
      code: supplier.code || '-',
      social_name: supplier.social_name || '-',
      document_number: supplier.document_number || '-',
      uf: supplier.state || '-',
      country: supplier.country || '-',
      score_serasa: supplier.score_serasa || 0,
      payment_deadline: supplier.PMP || 0,
      fqi: supplier.quality_percent || 0,
      saving_percent: supplier.saving_percent || 0,
    };
  };

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  useEffect(() => {
    async function loadItems(): Promise<void> {
      const items = location.state.selectedItems;
      setLineItems(formatLineItems(items));
      setContentItems(formatContentItems(items));
      for (const item of formatLineItems(items)) {
        if (
          item.buyer_group &&
          'id' in item.buyer_group &&
          'name' in item.buyer_group
        ) {
          setBuyerGroup({
            id: item.buyer_group.id,
            name: item.buyer_group.name,
          });
          break;
        }
      }
    }

    const response = loadItems();
    response.then(() => {
      showLoader(false);
    });
  }, [location]);

  useEffect(() => {
    loadSuggestions(lineItems, setSuggestions);
  }, [lineItems]);

  function handleSupplierObservation(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setSupplierObservation(event.target.value);
  }

  const toggleSort = (field: string) => {
    if (!lineItems) return;
    const sort = sortField.value === 'asc' ? 'desc' : 'asc';
    if (sortField.name === field) {
      setSortField({ name: field, value: sort });
    } else {
      setSortField({ name: field, value: 'asc' });
    }
    if (sort === 'asc') {
      lineItems.sort((a, b) => (b[field] > a[field] ? 1 : -1));
    } else {
      lineItems.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    }
  };

  const handleChangeShippingDate = (date: Date, index: number) => {
    const newItems = lineItems.slice();
    newItems[index].dt_delivery = date;
    setLineItems([...newItems]);
  };

  function handleIncludeObservation(
    event: React.ChangeEvent<HTMLInputElement>,
    item: LineItems,
  ) {
    item.observation = event.target.value;
  }

  function handleUnselectSupplier(content: cardContent) {
    const supplierIndex = suppliers.findIndex(sup => sup.id === content.id);
    const supplier = suppliers[supplierIndex];
    if (supplier) supplier.selected = false;

    const newSupplierCardContent = supplierCardContent.slice();

    const contentIndex = newSupplierCardContent.findIndex(
      cont => cont.id === content.id,
    );

    newSupplierCardContent.splice(contentIndex, 1);
    setSupplierCardContent(newSupplierCardContent);
    const selecteds = selectedSuppliers.filter(
      sup => sup.id !== suppliers[supplierIndex]?.id,
    );
    setSelectedSuppliers([...selecteds]);
  }

  const handleSubmitForm = async () => {
    const newItems = lineItems.map(item => ({
      item_id: item.id,
      observation: item.observation || '',
      dt_delivery: item.dt_delivery || null,
    }));
    const newSuppliers = supplierCardContent.map(item => ({ id: item.id }));
    const payload = {
      deadline: format(dtDelivery || new Date(), 'yyyy-MM-dd HH:mm:ss'),
      supplier_observation: supplierObservation,
      suppliers: newSuppliers,
      item_rfq: newItems,
      grp_purchasing: buyerGroup || null,
      create_vendor_list: createVendorList,
    };

    let deadlineError = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newDeadlineErrorIds: any = [];
    if (dtDelivery) {
      lineItems.forEach(item => {
        const dtDeadlineFormat = moment(dtDelivery).format(
          'MM/DD/YYYY HH:mm:ss',
        );

        const dtDeliveryFormat = moment(item.dt_delivery).format(
          'MM/DD/YYYY HH:mm:ss',
        );
        const dtDeliveryIsBeforeDeadline = moment(dtDeliveryFormat).isBefore(
          dtDeadlineFormat,
          'days',
        );
        if (dtDeliveryIsBeforeDeadline) {
          newDeadlineErrorIds.push(item.id);
          if (!deadlineError) {
            deadlineError = true;
          }
        }
      });
    }
    setDeadlineErrorIds(newDeadlineErrorIds);

    const emptyRequiredInputs = getEmptyRequiredInputs([
      {
        fieldObject: dtDelivery,
        fieldName: 'dtDelivery',
      },
      {
        fieldObject: newSuppliers,
        fieldName: 'newSuppliers',
      },
      {
        fieldObject: newItems,
        fieldName: 'newItems',
      },
    ]);

    setInputsWithErrors(emptyRequiredInputs);
    const hasEmptyRequiredInputs = emptyRequiredInputs.length > 0;

    async function createNewQuotation(): Promise<void> {
      if (hasEmptyRequiredInputs) {
        return setErrorModalIsOpen(true);
      }

      if (deadlineError) {
        return setShowModalDeadlineError(true);
      }

      showLoader(true);
      await api
        .post('/rfqs/add_with_relations/', payload)
        .then(response => {
          if (response.status === 201) {
            openModal();
          }
        })
        .catch(setResponseError)
        .finally(() => {
          showLoader(false);
        });
    }

    createNewQuotation();
  };

  const existsDeadlineError = (id: string | number | Object | Date) =>
    deadlineErrorIds.findIndex(
      (deadLineErrorItem: string | number | Object | Date) =>
        deadLineErrorItem === id,
    ) !== -1;

  const handleSelectSupplier = (option: string) => {
    const supIndex = suppliers.findIndex(
      supplier => supplier.code + ': ' + supplier.social_name === option,
    );
    const supplier = suppliers[supIndex];
    const index = suggestions.findIndex(sug => sug.id === supplier.id);
    if (index >= 0) suggestions.splice(index, 1);
    if (!selectedSuppliers.some(item => item.id === supplier.id)) {
      supplier.selected = true;
      setSupplierCardContent([
        ...supplierCardContent,
        handleSupplierCard(supplier),
      ]);
      selectedSuppliers.push({ id: supplier.id, value: supplier.social_name });
      setSelectedSuppliers([...selectedSuppliers]);
    }
  };

  const onSubmit = () => {
    handleSubmitForm();
  };

  const handleBackToPanel = () => {
    history.goBack();
  };

  const handleLoadedSuppliers = (items: Supplier[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];
    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach(item => {
      const newSupplier = {
        id: item.id,
        value: item.code + ': ' + item.social_name,
      };
      options.push(newSupplier);
      const supIndex = suppliers.findIndex(sup => sup.id === item.id);
      if (supIndex < 0) {
        suppliers.push(item);
      }
    });
    setSuppliersOptions([...options]);
    setSuppliers([...suppliers]);
  };

  function handleBuyerGroup(option: string) {
    const buyer = buyerGroups.find(bg => bg.label === option);
    if (buyer) setBuyerGroup({ name: buyer?.label, id: buyer.id });
  }

  const handleLoadedBuyerGroup = (
    items: never[],
    name: string,
    loadedOptions: ItemsOptionProps[] = [],
  ) => {
    items.forEach((item: { name: string; id: number }) => {
      const newGroup = {
        label: item.name,
        id: item.id,
      };
      loadedOptions.push(newGroup);
    });
    setBuyerGroups([...loadedOptions]);
  };

  const closeErrorPage = () => setResponseError(null);

  return (
    <>
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={closeErrorPage}
        />
      ) : (
        <Container className="flex-1 flex-col bg-primary h-full min-h-screen">
          {loading && <Loading />}
          <Navbar pageTitle={''} selectedMenu="panel" />
          <div className="flex items-center px-12 xl:px-20">
            <span className="font-default font-normal text-white text-sm">
              {t('purchase_panel')}
            </span>
            <IconBreadcrumb className="h-2 w-2 mx-4" />
            <span className="font-default font-bold text-white text-sm">
              {t('new_quote')}
            </span>
          </div>
          <div className="flex overflow-y-auto w-full px-12 xl:px-20 justify-center">
            <Content
              onSubmit={handleSubmit(onSubmit)}
              className="w-full flex-col flex flex-1 mt-6 bg-white px-12 pt-6 mb-6"
            >
              <div className="flex justify-between">
                <span className="font-default text-xl text-yaleblue-800 font-semibold">
                  {t('creating_new_quote')}
                </span>
                <div
                  className="h-3 w-3 cursor-pointer"
                  onClick={handleBackToPanel}
                >
                  <CloseIcon />
                </div>
              </div>
              <div
                className={`flex flex-col ${
                  inputsWithErrors.includes('newItems')
                    ? ' rounded-lg border border-red-salsa-500 '
                    : ''
                }`}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <span className="font-default font-semibold text-lg text-yaleblue-800">
                      1. {t('requests')}
                    </span>
                    {inputsWithErrors.includes('newItems') && (
                      <div className="pl-2">
                        <AlertIcon />
                      </div>
                    )}
                  </div>

                  <div className="flex items-center">
                    <span className="font-default font-semibold text-sm text-yaleblue-900">
                      {`${lineItems?.length} ${t('requests_selecteds')}`}
                    </span>
                    <div
                      className="h-4 w-4 z-100 cursor-pointer ml-2"
                      onClick={() => setRequestIsOpen(!requestIsOpen)}
                    >
                      {requestIsOpen ? <ChavronDownIcon /> : <ChavronUpIcon />}
                    </div>
                  </div>
                </div>

                {requestIsOpen &&
                  (lineItems && lineItems.length > 0 ? (
                    <div className="mt-10">
                      <div className="flex px-4 w-full justify-between items-center">
                        {fields.map((field, index) => (
                          <div
                            key={index}
                            className={`flex items-center ${
                              field.size || 'flex-1'
                            }`}
                          >
                            <div className="flex items-center w-5 h-5">
                              {sortField.name === field.label ? (
                                sortField.value === 'desc' ? (
                                  <SortDescIcon
                                    className="cursor-pointer w-3 h-2.5"
                                    onClick={() => toggleSort(field.label)}
                                  />
                                ) : (
                                  <SortAscIcon
                                    className="cursor-pointer w-3 h-2.5"
                                    onClick={() => toggleSort(field.label)}
                                  />
                                )
                              ) : (
                                <SortDescIcon
                                  className="cursor-pointer w-3 h-2.5"
                                  onClick={() => toggleSort(field.label)}
                                />
                              )}
                            </div>
                            <span
                              title={`${t(field.label)}`}
                              className="font-default select-none font-bold text-sm text-bluejeans-800 truncate"
                            >
                              {t(field.label)}
                            </span>
                          </div>
                        ))}
                        <div className="flex h-3 w-4" />
                      </div>
                      {
                        <>
                          {lineItems.map((item, index) => {
                            const FeaturedComponent: React.FC = () => {
                              const labels = [
                                {
                                  label: item.requisition,
                                  size: 'md:w-1/12 lg:w-1/12',
                                },
                                {
                                  label: item.item,
                                  size: 'md:w-1/12 lg:w-1/12',
                                },
                                {
                                  label: item.material,
                                  size: 'md:w-1/12 lg:w-1/12',
                                },
                                {
                                  label: item.center,
                                  size: 'md:w-1/12 lg:w-1/12',
                                },
                                {
                                  label: item.warehouse,
                                  size: 'md:w-1/12 lg:w-1/12',
                                },
                                {
                                  label: item.description,
                                  size: 'md:w-1/12 lg:w-1/12',
                                },
                                {
                                  label: item.urgency,
                                  size: 'md:w-1/12 lg:w-1/12',
                                },
                                {
                                  label: item.sla_deadline_raw,
                                  dt_lib_rc: item.dt_lib_rc,
                                  size: 'md:w-1/12 lg:w-1/12',
                                  deadline: true,
                                },
                                {
                                  shippingDate: true,
                                  size: 'md:w-1/12 lg:w-1/12',
                                  target: false,
                                  lead_time: false,
                                },
                              ];

                              return (
                                <>
                                  {labels.map((field, key) => (
                                    <span
                                      className={`font-default font-normal text-sm text-yaleblue-900
                                ${field.size || 'flex-1'}`}
                                      key={key}
                                    >
                                      {field.deadline && (
                                        <div className="flex items-center">
                                          <DeadlineDonut
                                            deadlineDate={field.label}
                                            createdAt={field.dt_lib_rc}
                                          />
                                        </div>
                                      )}
                                      {field.shippingDate && (
                                        <div className="md:w-1/12 lg:w-1/12 items-center flex">
                                          <ErrorBox
                                            error={existsDeadlineError(item.id)}
                                          >
                                            <div className="w-4 h-4 flex items-center">
                                              <CalendarIcon />
                                            </div>
                                            <CustomDatePicker
                                              selected={
                                                item.dt_delivery
                                                  ? new Date(item.dt_delivery)
                                                  : null
                                              }
                                              placeholder={
                                                item.dt_delivery
                                                  ? ''
                                                  : '00/00/0000'
                                              }
                                              onChange={(date: Date) =>
                                                handleChangeShippingDate(
                                                  date,
                                                  index,
                                                )
                                              }
                                              inputStyle="bg-transparent w-24 pl-2 font-default font-normal text-sm text-yaleblue-1000"
                                              minDate={new Date()}
                                            />
                                            {existsDeadlineError(item.id) && (
                                              <BoxAlert className="pl-2">
                                                <Tooltip
                                                  placement="top"
                                                  overlay={t(
                                                    'deadline_modal_error',
                                                  )}
                                                  align={{ offset: [0, 4] }}
                                                >
                                                  <AlertIcon />
                                                </Tooltip>
                                              </BoxAlert>
                                            )}
                                          </ErrorBox>
                                        </div>
                                      )}
                                      {!field.shippingDate && !field.deadline && (
                                        <div
                                          className="truncate"
                                          title={`${field.label}`}
                                        >
                                          {field.label
                                            ? removeZerosLeft(
                                                field.label.toString(),
                                              )
                                            : '-'}
                                        </div>
                                      )}
                                    </span>
                                  ))}
                                </>
                              );
                            };

                            const ContentComponent: React.FC = () => {
                              const content: ContentItems[] = [];
                              const lastPurchaseInfo: ContentItems[] = [];
                              Object.entries(contentItems[index]).forEach(i => {
                                const data: ContentItems = {
                                  label: i[0],
                                  value: i[1] ? removeZerosLeft(i[1]) : '-',
                                };
                                if (i[0].includes('last_purchase')) {
                                  lastPurchaseInfo.push(data);
                                } else {
                                  content.push(data);
                                }
                              });
                              return (
                                <>
                                  <div className="flex">
                                    <div className="flex flex-wrap w-1/2">
                                      {contentItems &&
                                        content.map((item, key) => (
                                          <ContentContainer
                                            className="flex justify-center pt-4"
                                            key={`content-${key}`}
                                          >
                                            <div className="flex flex-col px-4">
                                              <span className="font-default font-normal text-xs text-yaleblue-1000">
                                                {t(item.label)}
                                              </span>
                                              <div className="font-default font-normal text-sm text-yaleblue-1000 pt-2.5">
                                                <span className="font-default font-normal text-xs text-yaleblue-1000 break-words">
                                                  {item.label ===
                                                  'creation_date_rc'
                                                    ? format(
                                                        new Date(item.value),
                                                        'dd/MM/yyyy',
                                                      )
                                                    : t(item.value)}
                                                </span>
                                              </div>
                                            </div>
                                          </ContentContainer>
                                        ))}
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                      <div className="flex">
                                        <span className="font-default font-semibold text-sm text-yaleblue-1000">
                                          {t('last_purchase')}
                                        </span>
                                      </div>
                                      <div className="flex flex-wrap">
                                        {lastPurchaseInfo.map((info, index) => (
                                          <div
                                            key={`info-${index}`}
                                            className={
                                              info.label ===
                                              'last_purchase_observation'
                                                ? 'w-full'
                                                : ''
                                            }
                                          >
                                            {info.label ===
                                            'last_purchase_observation' ? (
                                              <div className="flex py-4">
                                                <div className="flex flex-col w-full">
                                                  <span className="font-default font-bold text-xs text-basic-gray-500">
                                                    {`${t(
                                                      'add_observation_to_quote',
                                                    )}:`}
                                                  </span>
                                                  <div className="flex w-full items-end justify-between mt-2">
                                                    <Input
                                                      name="observation"
                                                      placeholder={t(
                                                        'observation',
                                                      )}
                                                      className="w-2/3"
                                                      onChange={event =>
                                                        handleIncludeObservation(
                                                          event,
                                                          item,
                                                        )
                                                      }
                                                      value={item.observation}
                                                      autoComplete="off"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="flex justify-start items-center w-40 h-24">
                                                <div className="flex flex-col">
                                                  <span className="font-default font-normal text-xs text-yaleblue-1000">
                                                    {t(info.label)}
                                                  </span>
                                                  <div className="mt-2 flex justify-center">
                                                    <span className="font-default font-normal text-xs text-yaleblue-1000">
                                                      {info.label ===
                                                      'last_purchase_vl_unit_price'
                                                        ? formatValue(
                                                            info.value,
                                                          )
                                                        : info.label ===
                                                          'last_purchase_creation'
                                                        ? format(
                                                            new Date(
                                                              info.value,
                                                            ),
                                                            'dd/MM/yyyy',
                                                          )
                                                        : t(info.value)}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            };

                            return (
                              <ListItem
                                key={`item-${index}`}
                                FeaturedComponent={FeaturedComponent}
                                ContentComponent={ContentComponent}
                                className="mt-2"
                                id={index}
                                isSelected
                              />
                            );
                          })}
                        </>
                      }
                    </div>
                  ) : (
                    <div>
                      <span>{t('empty_list')}</span>
                    </div>
                  ))}
              </div>
              <div className="flex flex-col mt-10">
                <div className="flex items-center">
                  <span className="font-default font-semibold text-lg text-yaleblue-800">
                    2. {t('suppliers')}
                  </span>
                  {inputsWithErrors.includes('newSuppliers') && (
                    <div className="pl-2">
                      <AlertIcon />
                    </div>
                  )}
                </div>

                <div className="flex w-full mt-4">
                  <MultiInput
                    register={register}
                    icon={AddIcon}
                    name={'suppliers'}
                    options={suppliersOptions}
                    className="w-2/5 mt-4"
                    placeholder={'Buscar por nome, código ou CNPJ'}
                    autoComplete="off"
                    handleSelectOption={handleSelectSupplier}
                    type={'select'}
                    url={'suppliers/list_to_filter/?status=Liberado&supplier='}
                    loadOptions={handleLoadedSuppliers}
                    selectedOptions={selectedSuppliers}
                    key={selectedSuppliers.length}
                    hasError={inputsWithErrors.includes('newSuppliers')}
                  />
                  <div className="flex ml-4">
                    <AppliedFilters
                      appliedFilters={suggestions.slice(0, 6).map(sug => {
                        return sug.code + ': ' + sug.social_name;
                      })}
                      className={
                        'text-bluejeans-1000 font-semibold cursor-pointer'
                      }
                      toogleAppliedFilter={addItem}
                      selectedItems={selectedSuppliers}
                      setSelectedItems={setSelectedSuppliers}
                      cardContent={supplierCardContent}
                      setCardContent={setSupplierCardContent}
                      handleCard={handleSupplierCard}
                      suggestions={suggestions}
                      setSuggestions={setSuggestions}
                    />
                    {suggestions.length > 0 && (
                      <span className="text-basic-gray-400 text-sm pr-4 pt-4">
                        {t('suggested_suppliers')}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex mt-4 flex-wrap">
                  {supplierCardContent.length > 0 &&
                    supplierCardContent.map((item, key) => (
                      <SupplierCard
                        key={`sup-content-${key}`}
                        cardContent={item}
                        handleAction={handleUnselectSupplier}
                      />
                    ))}
                </div>
              </div>
              <div className="flex flex-col mt-10">
                <div className="flex items-center">
                  <span className="font-default font-semibold text-lg text-yaleblue-800">
                    3. {t('limit_date')}
                  </span>
                  {inputsWithErrors.includes('dtDelivery') && (
                    <div className="pl-2">
                      <AlertIcon />
                    </div>
                  )}
                </div>
                <div className="flex mt-4">
                  <DatePicker
                    selected={dtDelivery}
                    onChange={(date: Date) => setDtDelivery(date)}
                    minDate={new Date()}
                    placeholder="dd/mm/aaaa"
                    hasError={inputsWithErrors.includes('dtDelivery')}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-10">
                <div className="flex items-center">
                  <span className="font-default font-semibold text-lg text-yaleblue-800">
                    4. {t('closing_time')}
                  </span>
                  {inputsWithErrors.includes('dtDelivery') && (
                    <div className="pl-2">
                      <AlertIcon />
                    </div>
                  )}
                </div>
                <div className="flex mt-4">
                  <TimePicker
                    selected={dtDelivery}
                    onChange={(date: Date) => setDtDelivery(date)}
                    hasError={inputsWithErrors.includes('dtDelivery')}
                  />
                </div>
              </div>
              <div className="mt-10">
                <div className="flex items-center">
                  <div className="text-lg text-yaleblue-800 font-semibold pr-2.5">
                    {`5. ${t('buyer_group')}`}
                  </div>
                </div>
                <CustomDropDown
                  toggleAction={handleBuyerGroup}
                  options={buyerGroups}
                  title={t('select')}
                  url={'/buyer_groups/list_to_filter/?name='}
                  handleOptions={handleLoadedBuyerGroup}
                  selectedItem={buyerGroup?.name}
                />
              </div>
              <div className="flex flex-col mt-10">
                <div className="flex items-center">
                  <span className="font-default font-semibold text-lg text-yaleblue-800">
                    6. {t('supplier_note')}
                  </span>
                  {inputsWithErrors.includes('supplierObservation') && (
                    <div className="pl-2">
                      <AlertIcon />
                    </div>
                  )}
                </div>
                <div className="flex mt-4 items-center">
                  <div className="w-1/3">
                    <Input
                      className="w-full rounded-lg"
                      placeholder={t('observation')}
                      name="supplierNote"
                      onChange={handleSupplierObservation}
                      value={supplierObservation}
                      autoComplete="off"
                      hasError={inputsWithErrors.includes(
                        'supplierObservation',
                      )}
                    />
                  </div>
                  <button
                    type="button"
                    className={`h-8 px-2 w-40 flex ml-3 items-center justify-between bg-white text-sm text-bluejeans-800 font-normal rounded-lg border-2`}
                  >
                    {t('attach_file')}
                    <PaperIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
              <div className="flex justify-end mt-10 h-full items-end pb-4">
                {createVendorList ? (
                  <CheckBoxChecked
                    className="mb-3 mr-1.5 cursor-pointer"
                    onClick={handleVendorList}
                  />
                ) : (
                  <CheckBoxUnchecked
                    className="mb-3 mr-1.5 cursor-pointer"
                    onClick={handleVendorList}
                  />
                )}
                <span className="mb-2.5 mr-3">{t('create_vendor_list')}</span>
                <Button type="button" onClick={() => handleSubmitForm()}>
                  {t('create_quote')}
                </Button>
              </div>
            </Content>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
          >
            <div className="flex flex-col justify-center items-center">
              <ConfirmationIcon />
              <div className="my-4">
                <p className="font-default font-normal text-sm text-yaleblue-1000">
                  {createVendorList
                    ? `${t('created_rfq_vendor')} `
                    : `${t('created_rfq')} `}
                  <span className="font-bold">{`"RFQ's"`}</span>
                </p>
              </div>

              <Button onClick={() => closeModal()}>
                {t('confirmation_modal')}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={errorModalIsOpen}
            onRequestClose={closeErrorModal}
            ariaHideApp={false}
            className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
          >
            <div className="flex flex-col justify-center items-center">
              <div className="w-full flex justify-end cursor-pointer">
                <CloseIcon onClick={() => closeErrorModal()} />
              </div>
              <DeleteIcon />
              <div className="my-4 mt-10 mb-7">
                <div className="font-default font-normal text-sm text-yaleblue-1000">
                  {t('form_with_empty_fields')}
                </div>
              </div>
              <div className="flex items-center ">
                <Button
                  className="h-12 px-8 text-base"
                  onClick={() => closeErrorModal()}
                >
                  {t('confirmation_modal')}
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={showModalDeadlineError && !errorModalIsOpen}
            onRequestClose={() => setShowModalDeadlineError(false)}
            ariaHideApp={false}
            className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
          >
            <div className="flex flex-col justify-center items-center">
              <div className="w-full flex justify-end cursor-pointer">
                <CloseIcon onClick={() => setShowModalDeadlineError(false)} />
              </div>
              <DeleteIcon />
              <div className="my-4 mt-10 mb-7">
                <div
                  style={{ textAlign: 'center' }}
                  className="font-default font-normal text-sm text-yaleblue-1000"
                >
                  {t('deadline_modal_error')}
                </div>
              </div>
              <div className="flex items-center ">
                <Button
                  className="h-12 px-8 text-base"
                  onClick={() => setShowModalDeadlineError(false)}
                >
                  {t('confirmation_modal')}
                </Button>
              </div>
            </div>
          </Modal>
        </Container>
      )}
    </>
  );
};

export default NewQuotation;
